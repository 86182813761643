import React, { Component, Fragment } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Logo } from "./Commoncomponents";
// import { HashLink as Link } from "react-router-hash-link";
import { NavLink as RRNavLink } from 'react-router-dom';
import { LanguagesOption, i18n } from './../containers/Language';

// function SearchBar () {
  // class SearchBar extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       inputValue: props.searchstring,
  //       goSearch: false
  //     };
  //     this.handleSearchChange = this.handleSearchChange.bind(this);
  //     this.handleSubmit = this.handleSubmit.bind(this);
  //   }
  
  //   handleSearchChange(event) {
  //     this.setState({
  //       inputValue: event.target.value
  //     })
  //   }

  //   handleSubmit(event) {
  //     // console.log("Go Search!");
  //     event.preventDefault();
  //     this.props.searchhandler(this.state.inputValue);
  //     // this.setState({
  //     //   goSearch:true,
  //     // })
  //     this.setState({
  //       inputValue: '',
  //     })
  //   }
  
  //   render() {
  //     // const { searchhandler } = this.props;
  //     // if (this.goSearch) {
  //     //   return(
  //     //     <Redirect to={{
  //     //       pathname: '/searchlanding',
  //     //       state: {
  //     //         searchString: this.state.searchString
  //     //       }
  //     //       }} 
  //     //     />
  //     //   )
  //     // }
  //     return (
  //       // <Form onSubmit={(e) => this.props.searchhandler(e, this.state.inputValue)}>
  //       <Form onSubmit={(e) => this.handleSubmit(e)}>
  //         <InputGroup id="search-navbar">
  //           <Input
  //               type="search"
  //               name="search"
  //               value={this.state.inputValue}
  //               onChange={this.handleSearchChange}
                
  //           />
  //           <InputGroupAddon addonType="append" onClick={(e) => this.handleSubmit(e)}>
  //             <i className="fas fa-search"></i>
  //           </InputGroupAddon>
  //         </InputGroup>
  //       </Form>
  //     )
  //   }
  // }

class NavLayout extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this)
    this.closeNavbar = this.closeNavbar.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      isOpen: false,
      goSearch: false,
      searchString: "",
    };
    
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeNavbar() {
    if (this.state.isOpen === true) {
    this.toggle();
    }
  }

  handleSearch(searchstring) {
    // event.preventDefault();
    // console.log('Search click! - ' + searchstring);
    console.log("Go Search!");
    if (searchstring.length > 0) {
      this.setState({
        goSearch: true,
        searchString: searchstring
      })
    }
  }

  // handleSearch(event, searchstring) {
  //   event.preventDefault();
  //   // console.log('Search click! - ' + searchstring);
  //   if (searchstring.length > 0) {
  //     this.setState({
  //       goSearch: true,
  //       searchString: searchstring
  //     })
  //   }
  // }

  render() {
    return (
      <Navbar className="w-100 py-0" expand="xl" id="nav">
        <article className="d-flex">
          <NavbarToggler onClick={this.toggle} className="border-0">
            <span>
              <i style={{ color: "#626262" }} className="fas fa-bars" />
            </span>
          </NavbarToggler>
          <NavbarBrand onClick={this.closeNavbar} tag={RRNavLink} exact to="/" className="ml-5 py-0">
            <Logo />
          </NavbarBrand>
          <NavbarBrand onClick={this.closeNavbar} tag={RRNavLink} exact to="/search" className="d-block d-xl-none">
            <i className="fas fa-search" style={{ fontSize: '1.2rem'}}/>
          </NavbarBrand>
        </article>
        <Collapse isOpen={this.state.isOpen} navbar>
          <article className="d-xl-flex">
            <Nav className="mr-auto text-center" navbar>
              <NavItem className="mb-xl-0">
                <NavLink 
                  onClick={this.closeNavbar} 
                  tag={RRNavLink} 
                  to="/seeanddo"
                >
                  {i18n.__("See and Do")}</NavLink>
              </NavItem>
              <NavItem className="mb-xl-0">
                <NavLink 
                  onClick={this.closeNavbar} 
                  tag={RRNavLink} 
                  exact 
                  to="/wheretogo"
                >
                  {i18n.__("Where to Go")}
                </NavLink>
              </NavItem>
              <NavItem className="mb-xl-0">
                <NavLink 
                  onClick={this.closeNavbar} 
                  tag={RRNavLink} 
                  exact 
                  to="/eventsandfestivals"
                >
                  {i18n.__("Events and Festivals")}
                </NavLink>
              </NavItem>

              <NavItem className="mb-xl-0">
                <NavLink
                    title="7641islands.ph"
                    target="_blank"
                    href="https://www.7641islands.ph/"
                  >
                    {i18n.__("7641 Islands")}
                </NavLink>
              </NavItem> 

              <NavItem className="mb-xl-0">
                <NavLink 
                  onClick={this.closeNavbar} 
                  tag={RRNavLink} 
                  exact 
                  to="/saveourspots"
                  id="sos-nav"
                >
                  {i18n.__("Save Our Spots")}
                </NavLink>
              </NavItem>

              <NavItem className="mx-xl-1 my-auto">{/* mx-xl-1 my-auto d-none d-xl-block */}
                <LanguagesOption />
              </NavItem>

              <NavItem className="mx-xl-1 my-auto d-none d-xl-block">
                <NavLink 
                  onClick={this.closeNavbar} 
                  tag={RRNavLink} 
                  exact 
                  to="/search"
                >
                  <i className="fas fa-search mx-auto" style={{margin: '0px 10px 0px 0px', fontSize: '1.2rem'}}/>
                </NavLink>
              </NavItem>
            </Nav>

              {/* <SearchBar searchstring="" searchhandler={this.handleSearch} />
              {this.state.goSearch &&
                <Redirect to={{
                  pathname: '/search',
                  state: {
                    searchString: this.state.searchString
                  }
                  }} 
                />
              } */}
          {/* <a target="_blank" href="http://thetourismdecade.philippines.travel/" rel="noopener noreferrer">
            <img src="/images/dot-tourismbadge.png" className=" ml-lg-2" style={{maxWidth: '100%'}} alt="dot-tourismbadge"/>
          </a> */}
          </article>
        </Collapse>
      </Navbar>
    );
  }
}

export default class NavigationSection extends Component {
  render() {
    return (
      <Fragment>
        <section id="dot-navigation" className="fixed-top" style={{backgroundColor: '#000'}}>
          <div className="web-container">
            <div className="row no-gutters">
              <NavLayout />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
