import React, { Component } from 'react';

import Translate  from "i18n-react-json";
import locales    from "./../locale";
import IconEnglish   from "./../../node_modules/language-icons/icons/en.svg";
import IconArabic    from "./../../node_modules/language-icons/icons/ar.svg";
import IconGerman    from "./../../node_modules/language-icons/icons/de.svg";
import IconHindi     from "./../../node_modules/language-icons/icons/hi.svg";
import IconMalay     from "./../../node_modules/language-icons/icons/ms.svg";
import IconJapanese  from "./../../node_modules/language-icons/icons/ja.svg";
import IconKorean    from "./../../node_modules/language-icons/icons/ko.svg";
import IconRussian   from "./../../node_modules/language-icons/icons/ru.svg";
import IconSpanish   from "./../../node_modules/language-icons/icons/es.svg";
import IconChinese   from "./../../node_modules/language-icons/icons/zh.svg";

const i18n = new Translate(locales);
i18n.setLocale(localStorage.getItem('locale') || 'en');

class LanguagesOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageSelection: [
        { option: "English", value: "en", icon: IconEnglish },
        { option: "العربية", value: "ar", icon: IconArabic },
        { option: "Deutsch", value: "de", icon: IconGerman },
        { option: "Español", value: "es", icon: IconSpanish },
        { option: "हिन्दी", value: "hi", icon: IconHindi },
        { option: "日本語", value: "ja", icon: IconJapanese },
        { option: "한국어", value: "ko", icon: IconKorean },
        { option: "Bahasa Melayu", value: "ms", icon: IconMalay },
        { option: "Русский", value: "ru", icon: IconRussian },
        { option: "中文", value: "zhHans", icon: IconChinese },
        { option: "中文(台灣)", value: "zhHant", icon: IconChinese }
      ],
      activeLanguage: null,
      dropdownOpen : false
    };

    this.toggle = this.toggle.bind(this);  
  }
  
  componentDidMount() {
    let detectedLanguage = navigator.language || navigator.userLanguage;
    console.log(detectedLanguage);
  }

  componentWillMount() {
    this.setActiveLanguage();
  }

  toggle(event) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
 
  setActiveLanguage() {
    const active = localStorage.getItem('locale') || 'en';
    this.state.languageSelection.map((data) => {
      if (data.value == active) {
        this.state.activeLanguage = data;
        return;
      }
    });
  }

  setLanguage(lang = 'en') {
    localStorage.setItem('locale', lang);
    window.location.reload();
  }

  dropdownToggle() {
    this.state.dropdownOpen = this.state.dropdownOpen ? false : true;
    document.querySelector('.nav-language-dropdown .dropdown-menu').style.display = this.state.dropdownOpen ? 'block' : 'none';
  }

  render() {
    return (
        <div className="nav-item dropdown nav-language-dropdown">
            <a onClick={() => this.dropdownToggle() } className="nav-link dropdown-toggle nav-language-selected" href="javascript: void(0)" id="language-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="flag-icon flag-icon-us"> </span>
                <img className="rounded-circle" width={'25px'} alt='English' src={ this.state.activeLanguage ? this.state.activeLanguage.icon : IconEnglish } />&nbsp;
            </a>
            <div className="dropdown-menu" aria-labelledby="language-dropdown">
                {
                    this.state.languageSelection.map((data, index) => {
                        return (
                            <a className="nav-language-item dropdown-item" 
                              key={index} href="#" 
                              onClick={ () => this.setLanguage(data.value)}
                            >
                              <img className="rounded-circle" 
                                width={'20px'} alt={data.option} 
                                src={ data.icon } 
                              /> 
                              { data.option }
                            </a>
                        );
                    })
                }
            </div>
        </div>
      /* </ButtonDropdown>
      </ButtonGroup> */
    );
  }
}
  
export { LanguagesOption, i18n };