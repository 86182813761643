import React, { Component, Suspense, lazy }  from 'react';
import ReactDOM from 'react-dom';
// import App from './containers/App';
import DotEvents from './containers/DotEvents';
import DotDestinations from './containers/DotDestinations';
import DotActivities from './containers/DotActivities';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, withRouter, Redirect } from 'react-router-dom';
import NavigationSection from './components/NavigationSection';
import WhereToGo from './containers/WhereToGo';
import Festivals from './containers/Festivals';
// import VariableWidth from './containers/test';
import Home from './containers/Home';
import SeeAndDo from './containers/SeeAndDo';
// import VideoGallery from './containers/VideoGallery';
import FooterSection from './containers/FooterSection';
import { HomeRedirect } from './components/Commoncomponents';
import PrivacyPolicy from './containers/PrivacyPolicy';
import SearchLandingPage from './containers/SearchLandingPage';
//import PhilippineShoppingFest from './containers/PhilippineShoppingFest';
// import DotRegions from './containers/DotRegions';

//Website Subsections (must be lazy loaded)
const MoreFunFromHome = lazy(() => import('./containers/MoreFunFromHome'));
//const WakeUpinPH = lazy(() => import('./containers/WakeUpPH/WakeUpinPH'));
// const WakeUpPHArticle = lazy(() => import('./containers/WakeUpPH/InnerArticle'));
const DOLECorp = lazy(() => import('./containers/DOLECorp'));
const AccreditationCorp = lazy(() => import('./containers/AccreditationCorp'));
const SafePH = lazy(() => import('./containers/SafePH'));
// const SafeTrip = lazy(() => import('./containers/SafeTrip/SafeTrip'));
const FarmTourism = lazy(() => import('./containers/FarmTourism'));
const TravelBetter = lazy(() => import('./containers/TravelBetter'));
const SafetySeal = lazy(() => import('./containers/SafetySeal'));

const MoreFunAwaits = lazy(() => import('./containers/MoreFunAwaits/MFALanding'));
const MoreFunAwaitsArticle = lazy(() => import('./containers/MoreFunAwaits/FeaturedArticle'));

const ShootYourShot = lazy(() => import('./containers/UGC/ShootYourShot'));
const UGCJudges = lazy(() => import('./containers/UGC/UGCJudges'));
const FilipinoProducts = lazy(() => import('./containers/UGC/FilipinoProducts'));
const PHPeople = lazy(() => import('./containers/UGC/PHPeople'));
const PHDestinations = lazy(() => import('./containers/UGC/PHDestinations'));
const IGReels = lazy(() => import('./containers/UGC/IGReels'));
const YTShorts = lazy(() => import('./containers/UGC/YTShorts'));
const TiktokVids = lazy(() => import('./containers/UGC/TiktokVids'));
const Mechanics = lazy(() => import('./containers/UGC/Mechanics'));

const LovePhilippines = lazy(() => import('./containers/LovePhilippines'));

const ScrollToTop = withRouter(
  class extends Component {
    componentDidUpdate(prevProps) {
      // if (!prevProps.location.pathname.includes("/seeanddo")) {
      //   if (this.props.location.pathname !== prevProps.location.pathname) {
      //     window.scrollTo(0, 0);
      //   }
      // }
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }
    render() {
      return this.props.children;
    }
  }
);

class DotApp extends Component {
  render() {
    return (
      <Router>
        <div>
          <NavigationSection />
          <ScrollToTop>
            <Suspense fallback={<div>Loading...</div>}>          
              <Switch>

                <Route path="/lovethephilippines" component={LovePhilippines} />

                <Route path="/shootyourshot/mechanics" component={Mechanics} />
                <Route path="/shootyourshot/video/tiktokvideos" component={TiktokVids} />
                <Route path="/shootyourshot/video/youtubeshorts" component={YTShorts} />
                <Route path="/shootyourshot/video/instagramreels" component={IGReels} />
                <Route path="/shootyourshot/photo/philippinedestinations" component={PHDestinations} />
                <Route path="/shootyourshot/photo/peopleofthephilippines" component={PHPeople} />
                <Route path="/shootyourshot/photo/filipinoproducts" component={FilipinoProducts} />
                <Route path="/shootyourshot/prizesandjudges" component={UGCJudges} />
                <Route path="/shootyourshot" component={ShootYourShot} />

                <Route path="/accreditation/safetyseal" component={SafetySeal} />
                {/* <Route path="/safetrip/:nodeid" component={SafeTrip} /> */}
                {/* <Route path="/regions/:nodeid" component={DotRegions} /> */}
                <Route path="/safetrip" component={SafePH} />
                <Route path="/cashforwork" component={DOLECorp} />
                <Route path="/accreditation" component={AccreditationCorp} />

                <Route path="/morefunawaits/article/:nodeid" component={MoreFunAwaitsArticle} />
                <Route path="/morefunawaits" component={MoreFunAwaits} />
                <Route path="/wakeupinph"><Redirect to="/morefunawaits" /></Route>

                <Route path="/fromhome" component={MoreFunFromHome} />

                <Route path="/safeph"><Redirect to="/safetrip" /></Route>
                <Route path="/search/:srcstr" component={SearchLandingPage} />
                <Route path="/search" component={SearchLandingPage} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/saveourspots" component={TravelBetter} />
                <Route path="/futurefarms/:nodeid?/:episodeid?" component={FarmTourism} />
                <Route path='/seeanddo/:filtertype' component={SeeAndDo} /> 
                {/* <Route path='/videogallery' component={VideoGallery} /> */}
                <Route path='/seeanddo' component={SeeAndDo} />
                <Route path='/eventsandfestivals' component={Festivals} />
                <Route path='/wheretogo' component={WhereToGo} />
                <Route path="/events/:nodeid" component={DotEvents} />
                <Route path="/destinations/:nodeid" component={DotDestinations} />
                <Route path="/activities/:nodeid" component={DotActivities} />
                <Route path="/home" component={HomeRedirect} />
                <Route path="/" component={Home} />
              </Switch>
            </Suspense>
          </ScrollToTop>
          <FooterSection />
  {/*         <PurpleFooterSection />*/}
        </div>
      </Router>
    )
  }
}

ReactDOM.render(<DotApp />, document.getElementById('root'));
// registerServiceWorker();
unregister();