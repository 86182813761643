import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Input, InputGroupAddon, InputGroup, Button, Form } from 'reactstrap';
// import { Link } from "react-router-dom";
import { DisplayActivityInterest, DisplayEvent, DisplayDestination } from '../components/Commoncomponents';
// import { HashLink as Link } from 'react-router-hash-link';
import { dotConfig } from "../config";
import { i18n } from './Language';

const RenderSearchResults = ({searchdata}) => {
	if (searchdata.length){
    return (
      searchdata.map(data =>{
        switch (data.content_type) {
          case "Activities":
          case "activity":
            return (<div className="d-inline-flex tileWidth" key={data.title}><DisplayActivityInterest activityInfo={data}/></div>)
            break;
          case "Festivals and Events":
          case "events":
            return (<div className="d-inline-flex tileWidth" key={data.title}><DisplayEvent eventinfo={data}/></div>)
            break;
          case "Destinations":
          case "destinations":
            return (<div className="d-inline-flex tileWidth" key={data.title} ><DisplayDestination destinationinfo={data}/></div>)
            break;
          default:
        }
      })      
    )    
	}
	else{
	  return (<div>No match found</div>)
  }
}

export class LoadMore extends Component {
    constructor (props){
      super (props);
      this.state = {limit: Number(this.props.limit)};
      this.loadMoreData = this.loadMoreData.bind(this);
    }
    
    componentDidUpdate (nextProps) {
      if (nextProps.filteredData !== this.props.filteredData){
        this.setState({limit: Number(this.props.limit)});
      }
    }
  
    loadMoreData() {
      this.setState({limit: this.state.limit + Number(this.props.limit)});
    }
  
    renderData() {
      const {filteredData} = this.props;
  
      let newdata = filteredData.slice(0, this.state.limit);
      
      return <RenderSearchResults searchdata={newdata} />
    }
  
    render() {
      const {filteredData} = this.props;
   
      if (filteredData.length < this.state.limit){
        return (<div className=" d-flex justify-content-center flex-wrap">{this.renderData()}</div>)
      }
      else {
        if (this.state.limit < filteredData.length){
        return (
          <Fragment>
            <div className=" d-flex justify-content-center flex-wrap">
              {this.renderData()}
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <Button outline color="secondary" className="m-3 search-loadmore mt-5" onClick={this.loadMoreData}>Load more</Button>
              </div>
            </div>
          </Fragment>
        )
      }
      else{
        return (<div className=" d-flex justify-content-center flex-wrap">{this.renderData()}</div>)
        }
      }
    }
  }

// function SearchBar () {
class SearchBarInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.searchstring,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleSearchChange(event) {
    this.setState({
      inputValue: event.target.value
    })
  }

  render() {
    return (
      <Form onSubmit={(e) => this.props.searchhandler(e, this.state.inputValue)}>
        <InputGroup id="searchbar-landing">
            <Input
                type="search"
                name="search"
                placeholder={i18n.__("What are you looking for?")}
                value={this.state.inputValue}
                onChange={this.handleSearchChange}
                autoFocus
            />
            <InputGroupAddon addonType="append" onClick={(e) => this.props.searchhandler(e, this.state.inputValue)}><i className="fas fa-search"></i></InputGroupAddon>
        </InputGroup>
      </Form>
    )
  }
}

class SearchResults extends Component {
  constructor(props){
    super(props);
    this.state = {
      searchresults: props.searchdata,
      loadLimit: 5,
      searchString: props.searchstring,
      isloading: props.isloading,
    };
    this.loadMore = this.loadMore.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.searchstring !== this.state.searchString) {
      this.setState({ searchString: nextProps.searchstring });
    }

    if (nextProps.searchdata !== this.state.searchresults) {
      this.setState({ searchresults: nextProps.searchdata });
    }

    if (nextProps.isloading !== this.state.isloading) {
      this.setState({ isloading: nextProps.isloading });
    }
  }

  loadMore() {
    this.setState({loadLimit: this.state.loadLimit + 4});
  }


  renderSearchBarResults (text) {
    return (
      <Container>
        <Row className="mt-5">
          <Col className="text-center mt-5" lg={{size: 8, offset: 2}}>
            <SearchBarInner searchstring={this.state.searchString} searchhandler={this.props.searchhandler} />
          </Col>
        </Row> 
        <Row className="my-5">
          <Col className="text-center">	 
            {
              this.state.searchString.length > 0 
              ?
                <h3>{text} "{this.state.searchString}"</h3> 
              :
                ""
            }
          </Col>
        </Row>                  
    </Container>
    )
  }

  render() {

    let { searchresults, isloading } = this.state;

    if (isloading) {
      return(
        this.renderSearchBarResults('Searching for ')
      )
    } else {

      if ( searchresults.length > 0 ) {
        return (
          <Fragment>
            {this.renderSearchBarResults(searchresults.length + ' results for')}
            <Container>
              <Row className="my-5">
                <Col>	
                    <LoadMore filteredData={this.state.searchresults} limit="8" />
                </Col>
              </Row>
            </Container>
          </Fragment>
        );
      } 
      else {
        return(
          this.renderSearchBarResults('No match found for')
        );
      }
    }

  }

    
}


export default class SearchLandingPage extends Component{
  constructor(props) {
      super(props);
      let tempscrstr = "";

      if (typeof this.props.match.params.srcstr != 'undefined') {
        tempscrstr = this.props.match.params.srcstr;
        // console.log(tempscrstr);
      }

      this.state = {
        activityFiltered: [],
        searchresults:[],
        searchString: tempscrstr,
        // searchString: "",
        isloading: false,
      };

      this.handleSearch = this.handleSearch.bind(this);
  }

  loadData() {
    // const fetchURL = '/data/searchresults.json';
    if (this.state.searchString !== '') {
      const fetchURL = dotConfig.apiUrl + 'searchapi/' + encodeURIComponent(this.state.searchString);
      this.setState({
        isloading: true,
      });

      fetch(fetchURL)
        .then(response => response.json())
        .then(response => {

          if (response.length > 0) {
            let tempdata = response.map(searchdata => {
              return searchdata.nid;
            });
            // console.log(tempdata.toString());

            const fetchDataURL = dotConfig.apiUrl + 'searchdataapi/' + tempdata.toString();
            fetch(fetchDataURL)
              .then(responsedata => responsedata.json())
              .then(responsedata => {
                this.setState({
                  searchresults: responsedata,
                  isloading: false
                });
              })
              .catch(error => {
                console.log(error);
              });

          } else {
            this.setState({
              searchresults: [],
              isloading: false
            });
          }
          // this.setState({
          //   searchresults: response,
          //   isloading: false
          // });
        })
        .catch(error => {
          console.log(error);
        });
      }
  }

  componentDidMount () {
   this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchString !== prevState.searchString) {
      this.setState({
        isloading: true,
      });
      this.loadData();
      // window.scrollTo(0, 0);
    }
  }

  handleSearch(event, searchstring) {
    event.preventDefault();
    // console.log('Search click! - ' + searchstring);
    if (searchstring.length > 0) {
      window.history.replaceState(null,null,'/search/' + encodeURI(searchstring));
      this.setState({
        searchString: searchstring
      });
    }
  }

      
	render(){
    // console.log(typeof this.state.searchString);
    // if (typeof this.state.searchString === undefined) {

    // }
    if (this.isloading) {
      return(
        <p>Loading...</p>
      )
    } else {
      return (
        <div className="pb-4">
          <SearchResults 
            searchstring={this.state.searchString}
            searchdata={this.state.searchresults}
            searchhandler={this.handleSearch}
            isloading={this.state.isloading}
          />
        </div>
      );
    }
	}
}

