// import en from "./en.json";
import ar from "./ar.json";
import de from "./de.json";
import hi from "./hi.json";
import ms from "./ms.json";
import ja from "./ja.json";
import ko from "./ko.json";
import ru from "./ru.json";
import es from "./es.json";
import zhHans from "./zh-Hans.json";
import zhHant from "./zh-Hant.json";

export default { /* en,  */ar, de, hi, ms, ja, ko, ru, es, zhHans, zhHant };