import React, { Component, Fragment } from 'react';
// import ScrollableAnchor from 'react-scrollable-anchor';
import { 
  Card,
  CardImg,
  CardText,
  CardBody,
  Button,
  // ButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Container,
  Row,
  Col } from 'reactstrap';
import { Banner, RenderContent, InnerLandingIntroTextLayout, RenderThreeCarousel, SubSection } from '../components/Commoncomponents';
import { WhereToStay, WhatsNearFold } from './Commoncontainers'
import { dotConfig } from '../config';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
// import PropTypes from 'prop-types';


class LocalSpecialtiesLayout extends Component {
  render() {
    const {specialties} = this.props;
    const car_content = specialties.map(speciality => {
      return (
        <div className="col-12 pt-3 mb-2" key={ speciality.title }>
          <Card>
            <CardImg className="rounded-0 img-fluid" width="100%" src={dotConfig.appDomain + speciality.field_main_image.replace(/\\\//g, "/") } alt="Card image cap" />
            <CardBody className="text-white pt-3 three-cards-layout">
              <CardText style={ { color: '#3a3a3a' } } className="font-weight-bold">
                { speciality.title }
              </CardText>
              <CardText style={ { lineHeight: '1', color: '#3a3a3a' } } className="mb-0">
                <small dangerouslySetInnerHTML={{ __html: speciality.field_description }} />
              </CardText>
            </CardBody>
          </Card>
        </div>
      )
    })

    return (<RenderThreeCarousel carouseldata={car_content} />)
  }
}

class InnerLocalSpecialties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialtiesData: [],
      nodeIds: this.props.nodeIds
    };
  }

  loadData() {
    const fetchURL = dotConfig.apiUrl + 'specialties/' + encodeURI(this.state.nodeIds.replace(/\s/g, '')) + '?_format=json';
    fetch(fetchURL)
      .then(response => response.json())
      .then(response => {
        this.setState({
          specialtiesData: response
        });
      })
      .catch((error) => {
        console.log(error);
    });
  }

static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.nodeIds !== prevState.nodeIds) {
      return {
        nodeIds: nextProps.nodeIds
      }
    }
  }

componentDidMount() {
    this.loadData();
}

componentDidUpdate(prevProps, prevState) {
    if (this.state.nodeIds !== prevState.nodeIds) {
      this.loadData();
    }
  }

render() {
  // if (this.state.nodeIds === "") {
  //   return null;
  // } else {
    if (this.state.specialtiesData.length > 0 ) {
      return (        
          <Container className="pb-4">
            <Row>
              <Col className="mt-3 mb-4">
                <RenderContent className="font-weight-bold orangebg py-3 px-3 mb-2 h5 text-white">
                  Local Specialties
                </RenderContent>
              </Col>
            </Row>
            <Row>
              <Col className="px-2 pb-4">
                <LocalSpecialtiesLayout specialties={ this.state.specialtiesData } />
              </Col>
            </Row>
          </Container>
        );
    } else {
      return null;
    }
  // }
}
}

// class ActivityGallery extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       fetchedContents: []
//     };
//   }

//   componentDidMount() {
//     const fetchURL = 'http://localhost:8080/dotdata/see-more-of.json';
//     fetch(fetchURL)
//       .then(response => response.json())
//       .then(response => {
//         this.setState({
//           fetchedContents: response
//         });
//       });
//   }

//   render() {
//     return (
//       <section className="darkgreybg pb-5">
//         <div className="container">
//           <div className="row pb-3">
//             <RenderContent className="font-weight-bold darkbluebg py-3 px-3 mb-2 h5 text-white">
//             See more of {this.props.title}
//             </RenderContent>
//           </div>
//           <CarouselComponent data={ this.state.fetchedContents } />
//         </div>
//       </section>
//       );
//   }
// }

// Main Component

class DotActivities extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activityData: [],
      activityNode: this.props.match.params.nodeid,
      dropdownOpen: false
    };
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
    if (!this.state.dropdownOpen) {
      document.body.classList.add('overlay-active');
    } else {
      document.body.classList.remove('overlay-active');
    }
  }


  loadData() {
    // const fetchURL = dotConfig.apiUrl + 'activities/' + this.state.activityNode + '?_format=json';
    const fetchURL = dotConfig.apiUrl + 'activities?alias=/activities/' + this.state.activityNode;
    fetch(fetchURL)
      .then(response => response.json())
      .then(response => {
        this.setState({
          activityData: response
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.nodeid !== prevState.activityNode) {
      return {
        activityNode: nextProps.match.params.nodeid
      }
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activityNode !== prevState.activityNode) {
      this.loadData();
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {activityData} = this.state;
    const bgArray = ["lightgreybg", "",  "darkgreybg", ""];
    var bgCounrter = 0;
    
    // console.log(locationData);

    if (activityData.length === 0) {
      return <div>
               <p>Loading...</p>
             </div>;
    } else {
      var activityDisplayData = activityData[0];
      // console.log('Near me: ' + activityDisplayData.field_whats_near_me_activities.length);
    }

    const background = `${dotConfig.appDomain}${activityDisplayData.field_main_banner.replace(/\\\//g, "/")}`;
    return (
      <Fragment>
        { /* <RenderImage src={"http://" + dotConfig.appDomain + activityDisplayData.field_main_banner.replace(/\\\//g, "/")} /> */ }
        <Banner background={ background } bannertext={ activityDisplayData.title } />
        <Container>
          <Row>
            <Col xs="12" lg={{size: 10, offset: 1}} className="text-center" key={activityDisplayData.title}>
              <article className="py-5 px-3" style={{ color: '#4b4b4b' }}>
                <RenderContent className="d-block">
                  <div style={{ wordWrap: 'break-word'}} dangerouslySetInnerHTML={{ __html:activityDisplayData.field_description }} />
                </RenderContent>
                <SubSection header="How to get there:" body={activityDisplayData.field_how_to_get_there} />
                <br />
                  {
                    activityDisplayData.hasOwnProperty('field_tours_link')
                      ? 
                        activityDisplayData.field_tours_link.length > 0 &&
                        <Button href={activityDisplayData.field_tours_link} target="_blank" className="booktour-btn font-weight-bold mt-2 px-sm-4 py-2">Book a Tour with <img src="/images/dot-gttplogo-booktour.png" alt="gttplogo" className="img-fluid" /></Button>
                      : 
                        ''
                  } 
              </article>
            </Col>
            {/* <Col className="d-none d-lg-block" lg="6">
              <img
                src="/images/dot-banig-large.jpg"
                className="mx-auto img-fluid d-block"
                alt="dot-banig-large"
              />
            </Col> */}
          </Row>
        </Container>
        {activityDisplayData.field_local_specialties.length > 0 && 
          <section className={bgArray[bgCounrter++]}>
            <InnerLocalSpecialties nodeIds={ activityDisplayData.field_local_specialties } />
          </section>
        }

        {activityDisplayData.field_where_to_stay.length > 0 && 
          <section className={bgArray[bgCounrter++]}>
            <WhereToStay nodeIds={ activityDisplayData.field_where_to_stay } />
            </section>
        }

        {activityDisplayData.field_whats_near_me_activities.length > 0 && 
          <section className={bgArray[bgCounrter++]} id="activities-whatsnear">
            <WhatsNearFold section="activities" nodeIds={ activityDisplayData.field_whats_near_me_activities } />
          </section>
        }
{/*            <article className="anchor-fab d-lg-none">
              <ButtonDropdown direction="up" isOpen={ this.state.dropdownOpen } toggle={ this.toggle }>
                <DropdownToggle style={ { opacity: '1' } } className="sticky-filter">
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                  <div className="clearfix">
                      <Button className="float-right pr-3 py-0 border-0 closeFilter" onClick={this.toggle}>&times;</Button>
                  </div>
                  <h3 className="text-center text-white">{ activityDisplayData.title }</h3>
                  <br />
                  <a href="#localspecialties">
                    <DropdownItem>
                      <h5 className="text-white">Local Specialties </h5>
                    </DropdownItem>
                  </a>
                  <a href="#wheretostay">
                    <DropdownItem>
                      <h5 className="text-white">Where to Stay </h5>
                    </DropdownItem>
                  </a>
                  <a href="#whatsnear">
                    <DropdownItem>
                      <h5 className="text-white">What's Near </h5>
                    </DropdownItem>
                  </a>
                  <a href="#whatsnear">
                    <DropdownItem>
                      <h5 className="text-white">Explore </h5>
                    </DropdownItem>
                  </a>
                </DropdownMenu>
              </ButtonDropdown>
            </article>*/}
            {/*<div className="d-none d-lg-flex col-lg-6 flex-column align-items-end">
              <a href="#localspecialties">
                <article className="position-relative">
                  <h2 className="text-white anchor-text">Local <br /> Specialties </h2>
                  <img className="img-fluid" alt="dot anchor specialties" src='/images/dot-anchor-seeanddo-local-specialties.jpg' />
                  <img className="anchor-image" alt="dot anchor" src="/images/dot-anchor-button.png" />
                </article>
              </a>
              <div className="d-flex">
                <a href="#wheretostay">
                  <article className="position-relative">
                    <h2 className="text-white anchor-text"> Where to <br /> Stay</h2>
                    <img className="img-fluid" alt="dot anchor where to stay" src='/images/dot-anchor-events-where-to-stay1.jpg' />
                    <img className="anchor-image" alt="dot anchor" src="/images/dot-anchor-button.png" />
                  </article>
                </a>
                <a href="#whatsnear">
                  <article className="position-relative">
                    <h2 className="text-white anchor-text">What's <br/> Near</h2>
                    <img  className="img-fluid" alt="dot anchor whatsnear" src='/images/dot-anchor-seeanddo-local-whatsnear1.jpg' />
                    <img className="anchor-image" alt="dot anchor button" src="/images/dot-anchor-button.png" />
                  </article>
                </a>
              </div>
              <article className="position-relative">
                <Link to={ destinationLink }>
                <h2 className="text-white anchor-text">Explore { festivalDisplayData.field_event_province }</h2>
                <img className="img-fluid" alt="dot anchor events explore" src='/images/dot-anchor-events-explore.jpg' />
                <img className="anchor-image" alt="dot anchor button" src="/images/dot-anchor-button.png" />
                </Link>
              </article>
            </div>*/}
         {/* 
       <ScrollableAnchor id={ 'localspecialties' }>
          <InnerLocalSpecialties nodeIds={ activityDisplayData.field_local_specialties } />
        </ScrollableAnchor>
        <ScrollableAnchor id={ 'wheretostay' }>
          <section>
            <WhereToStay nodeIds={ activityDisplayData.field_where_to_stay } />
          </section>
        </ScrollableAnchor>
            <ActivityGallery title={activityDisplayData.title} /> 
       <ScrollableAnchor id={ 'whatsnear' }>
          <section style={{ background: '#f1f0ed'}}>
            <WhatsNearFold nodeIds={ activityDisplayData.field_whats_near_me_activities } />
          </section>
        </ScrollableAnchor>*/}
      </Fragment>
      );
  }
}

export default DotActivities;