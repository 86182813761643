import React, {Component, Fragment} from 'react';
import { Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Row, Col } from 'reactstrap';
import { SectionHeader, RenderCarousel, LoadMore, InnerPageBanner, DisplayActivityInterest } from '../components/Commoncomponents';
import { dotConfig } from '../config';
// import { HashLink as Link } from 'react-router-hash-link';
// import News from './RelatedNews'
import { Link } from "react-router-dom";
import { i18n } from './Language';


const RenderSeeAndDoCarousel = ({carouseldata}) => {
    var car_content = carouseldata.map(function (carouselContent, index) {
           return (<div className="mb-2" key={index} ><DisplayActivityInterest activityInfo={carouselContent} /></div>)
        })
    return <RenderCarousel carouseldata={car_content}/>
  }

class FeaturedActivity extends Component {
    constructor(props) {
		super(props);
		this.state = {
			activitiesContent: []
		};
	}

	loadData() {
        const fetchURL = dotConfig.apiUrl + 'featuredactivitieslist/';
        fetch(fetchURL)
        .then(response => response.json())
        .then(response => {
            this.setState({
                activitiesContent: response
            });
        })
        .catch((error) => {
            console.log(error);
        });
    }

	componentDidMount() {
		this.loadData();
  }

  render() {
    return(
      <section className="content-seeanddo-mustsee text-white" id="content-mustsee">
        <Container className="pb-5">
          <Row id="activities-header">
            <Col>
              <SectionHeader title={i18n.__("MUST SEE and DO")} bgcolor="salmonheaderbg" />
            </Col>
          </Row>
          <Row>
            <Col className="px-4">
              <RenderSeeAndDoCarousel carouseldata={this.state.activitiesContent} />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
};

// Explore Interest Section

class FilterActivity extends Component{
    constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.handleScroll = this.handleScroll.bind(this);
      this.state = {
        dropdownOpen: false,
        value: i18n.__("Filter by Interest"),
        icon: "/images/icons/dot-filtericon.png",
        scrollY: "",
        categoryData:  [
          {
            title:"Dive",
            icon:"/images/icons/dot-explore-dive.png",
            tag:"Dive",
            nid: "dive"
          },
          {
            title:"Nature",
            icon:"/images/icons/dot-explore-nature.png",
            tag:"Nature",
            nid: "nature"
          },
          {
            title:"Sun and Beach",
            icon:"/images/icons/dot-explore-sun.png",
            tag:"Sun and Beach",
            nid: "sunandbeach"
          },
          {
            title:"Adventure",
            icon:"/images/icons/dot-explore-adventure.png",
            tag:"Adventure",
            nid: "adventure"
          },
          {
            title:"Food and Hospitality",
            icon:"/images/icons/dot-explore-food.png",
            tag:"Food and Hospitality",
            nid: "foodandhospitality"
          },
          {
            title:"Events and Culture",
            icon:"/images/icons/dot-explore-events.png",
            tag:"Events and Culture",
            nid: "eventsandculture"
          },
          // {
          //   title:"See All",
          //   icon: "/images/icons/seeall.png",
          //   tag: "See All",
          //   nid: "seeall"
          // }
        ]
      };
    }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll(event) {
    this.setState({
        scrollY: window.scrollY
    });

    let sectionsHeight = document.getElementById('dot-navigation').offsetHeight + document.getElementById('seeanddo-banner').offsetHeight;

    if (window.innerWidth < 992 && this.state.scrollY > sectionsHeight) {
        document.getElementById('activityfilter-mobile').classList.add('show-filter');
    }
    else{
        document.getElementById('activityfilter-mobile').classList.remove('show-filter');
    }
  }

  setValues(title, src){
    this.setState({
      icon: src,
      value: title
    });

    if (window.innerWidth < 768) {
        window.scrollTo(0, 300);
    }
    else {
      window.scrollTo(0, 500);
    }
  }

  toggle(event) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });

    if (!this.state.dropdownOpen){
      document.body.classList.add('overlay-active');
    }
    else {
      document.body.classList.remove('overlay-active');
    }
  }

  renderButtonFilter (categories){
    var filters =  categories.map(data => {
      if (window.innerWidth > 991) {
         return (
          <Link key={data.title} to={`/seeanddo#${data.nid}`} onClick={()=>{this.props.handleClick(data.title)}} className={`btn text-left btn-dark p-3 ${this.props.isActive(data.title)}`}><img className="mr-2" src={data.icon} alt={data.title} />{i18n.__(data.title)}</Link>
        )
      }
      else {
        return (
          <Link key={data.title} to={`/seeanddo#${data.nid}`} onClick={()=>{this.props.handleClick(data.title); this.setValues(data.title, data.icon)}}><DropdownItem className="text-left btn-dark px-3 text-white"><img className="mr-2" src={data.icon} alt={data.title}/>{i18n.__(data.title)}</DropdownItem></Link>
        )
      }
    })
    return (filters);
  }

  render(){
    return(
      <section className="filter-activity" id={this.props.activeID.replace(/[^\w\s]/gi, '')}>
        <SectionHeader title={i18n.__("Explore Interest")}/>
        <ButtonGroup vertical className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
          {this.renderButtonFilter(this.state.categoryData)}
          <Link to="/seeanddo" onClick={()=>{this.props.handleClick("See All")}} className={`btn text-left btn-dark p-3 ${this.props.isActive("See All")}`}><img className="mr-2" src="/images/icons/seeall.png" alt="See All" />{i18n.__("See All")}</Link>
        </ButtonGroup>
        <ButtonGroup className="d-lg-none d-xl-none">
          <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle  className="rounded-0 text-left p-3 select-activity"><img src={this.state.icon} className="mr-2" alt=""/>{this.state.value}</DropdownToggle>
            <DropdownToggle className="border-0 text-left p-3 select-activity sticky-filter" id="activityfilter-mobile"><img src={'/images/icons/dot-filtericon.png'} className="img-fluid float-right" alt="filtericon"/></DropdownToggle>
            <DropdownMenu className="rounded-0">
              <Button className="float-right pr-2 pt-3 border-0 closeFilter" onClick={this.toggle}>&times;</Button>
              {this.renderButtonFilter(this.state.categoryData)}
              <Link to="/seeanddo" onClick={()=>{this.props.handleClick("See All"); this.setValues("See All", "/images/icons/seeall.png")}}><DropdownItem className="text-left btn-dark px-3 text-white"><img className="mr-2" src="/images/icons/seeall.png" alt="See All"/>See All</DropdownItem></Link>
            </DropdownMenu>
          </ButtonDropdown>
        </ButtonGroup>
        {/* <Button href="https://guidetothephilippines.ph/trips-and-experiences?utm_source=dot&utm_medium=seedo-cta&utm_campaign=launch" target="_blank" className="booktour-btn font-weight-bold mt-4 d-flex align-items-center justify-content-center w-100">Book a Tour with <img src="/images/dot-gttplogo-booktour.png" alt="gttplogo" className="ml-2" />
        </Button> */}
      </section>
    );
  }
}

class ExploreInterest extends Component{
	constructor(props) {
    super(props);
    this.isActive = this.isActive.bind(this);
    this.state = {
      activeClass: "",
      activityContents: [],
      activityFiltered: []
      };
	  this.filterActivity = this.filterActivity.bind(this);
  }

  loadData(inFilter) {
    const fetchURL = dotConfig.apiUrl + 'activitieslist/';
    fetch(fetchURL)
      .then(response => response.json())
      .then(response => {
        this.setState({
          activityContents: response
        }, () => {this.filterActivity(inFilter)});
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    //Filter matrix

    let tempfilter = "See All";

    switch(this.props.activefilter.replace(/[^\w\s]/gi, '')) {
      case "dive":
        tempfilter = "Dive";
        break;
      case "nature":
        tempfilter = "Nature";
        break;
      case "sunandbeach":
        tempfilter = "Sun and Beach";
        break;
      case "adventure":
        tempfilter = "Adventure";
        break;
      case "foodandhospitality":
        tempfilter = "Food and Hospitality";
        break;
      case "eventsandculture":
        tempfilter = "Events and Culture";
        break;
      default:
        tempfilter = "See All";
    }

    this.loadData(tempfilter);
  }

  isActive(filter){
    if (this.state.activeClass === filter){
      return "active"
    }
    else {
      return ""
    }
  }

	filterActivity (toShow) {

    let { activityContents } = this.state;
    let newFilter;

    if (toShow === 'See All'){
      newFilter = activityContents;
    }
    else{
      newFilter = activityContents.filter(data => {
        return data.field_activity_type.split("|").find(category => {
            return category === toShow;
          })
      })
	  }
	  this.setState({activityFiltered: newFilter, activeClass: toShow});
  }
  
	render(){
		return(
			<section className="content-seeanddo-explore text-white pb-4">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-4 col-xl-3 mb-3 d-flex justify-content-center flex-wrap">
							<FilterActivity handleClick={this.filterActivity} isActive={this.isActive} activeID={this.props.hash}/>
						</div>
						<div className="col-12 col-lg-8 col-xl-9">
						  <LoadMore filteredData={this.state.activityFiltered} page="seeanddo" />
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default class SeeAndDo extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // seeAndDoFilter: this.props.match.params.filtertype
      seeAndDoFilter: this.props.location.hash
    };
  }

  render(){
		return (
      <Fragment >
        <InnerPageBanner id="seeanddo-banner" bg="/images/dot-seeanddo-banner.jpg" title="Catch a wave with Baler’s best." content="<strong>2018 Aurora Surf Cup</strong><br/>Baler, Aurora"/>
        <ExploreInterest activefilter={this.state.seeAndDoFilter} hash={this.props.location.hash}/>
        <FeaturedActivity />
{/*				  <News />*/}
      </Fragment>
    );
	}
}

