import React, {Component, Fragment} from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { dotConfig } from '../config';
// import { HashLink as Link } from 'react-router-hash-link';
import { InnerPageBanner, DisplayDestination } from '../components/Commoncomponents';
import { Container, Row, Col } from 'reactstrap';


class RenderRegionCarousel extends Component{
  constructor(props) {
    super(props);
    this.state = {
      throttled: false
    };
  }

  resize = () => {

    if (!this.state.throttled) {
      // actual callback action
      this.forceUpdate()
      // we're throttled!
      // throttled = true;
      this.setState({
        throttled: true
      })
      // set a timeout to un-throttle
      setTimeout(() => {
        this.setState({
          throttled: false
        })
      }, 500);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  render(){

    let slideScrollLG, slideScrollMD;

    let carouseldata = this.props.carouseldata;

    let setWidthClass = "";

    if (carouseldata.length < 4 && window.innerWidth > 991){
        setWidthClass = "setWhereToGoCard";
    }
    else {
      setWidthClass = "";
    };


    if (carouseldata.length >= 4){
		slideScrollLG = 4;
		slideScrollMD = 3;
		//   slideScrollLG = 3;
    //   slideScrollMD = 2;
      // slideScrollSM = 2;
    }
    else{
      slideScrollLG = carouseldata.length;
      slideScrollMD = carouseldata.length;
      // slideScrollSM = carouseldata.length;
    };

    var settings = {
          className: setWidthClass,
          lazyLoad: true,
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: slideScrollLG,
          slidesToScroll: slideScrollLG,
          responsive: [
            {
              breakpoint: 1200,
              settings:{
                slidesToShow: slideScrollMD,
                slidesToScroll: slideScrollMD
              }
            },
            {
              breakpoint: 991,
              settings:{
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 767,
              settings:{
              	className: setWidthClass,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

    return(
         <Slider {...settings}>
                {carouseldata}
         </Slider>
      );
    }
}

// const RenderCardData = ({contentInfo}) => {
//   const {field_feature_image, title, field_province} = contentInfo;
//   //   const destinationLink = "/destinations/" + contentInfo.nid;
//   const linkarray = contentInfo.url_alias.split('/');
//   const destinationLink = "/destinations/" + linkarray[linkarray.length - 1];
//   return(
//     <Link to={destinationLink}>
// 	    <Card inverse className="border-0 card-image-overlay"  id="setCard">
// 	        <CardImg width="100%" src={dotConfig.appDomain + field_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt=""/>
// 	        <CardImgOverlay>
// 	         	<h4 className="font-weight-bold" dangerouslySetInnerHTML={{__html: title}}/>
// 	            <p className="location position-absolute" dangerouslySetInnerHTML={{__html: field_province}} />
// 	        </CardImgOverlay>
// 	    </Card>
//     </Link>
//   );
// }

const RenderWhereToGoCarousel = ({regionname, carouseldata}) => {
	var car_content = carouseldata.map(function (carouselContent, index) {
		//    return (<RenderCardData key={index} contentInfo={carouselContent} />)
		return (<DisplayDestination key={index} regionname={regionname} destinationinfo={carouselContent} />)
		})
	return <RenderRegionCarousel carouseldata={car_content}/>
}

class CardCarousel extends Component {
	  constructor(props) {
		  super(props);
		  this.state = {
			 carouselContents: [],
			 nodeIds: this.props.nodeIds,
			 regionName: this.props.regionname
		  };
		}

		loadData() {
			const fetchURL = dotConfig.apiUrl + 'destinationslist/' + encodeURI(this.state.nodeIds.replace(/\s/g, ''));
			fetch(fetchURL)
			.then(response => response.json())
			.then(response => {
				this.setState({
					carouselContents: response
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.nodeIds !== prevState.nodeIds) {
			return {
				nodeIds: nextProps.nodeIds
			}
		}
	}

	componentDidMount() {
			this.loadData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.nodeIds !== prevState.nodeIds) {
			this.loadData();
		}
	}

	render() {
		return(
			  <RenderWhereToGoCarousel regionname={this.state.regionName} carouseldata={this.state.carouselContents} />
		  );
	}
  };

const Regions = ({ regionInfo,sectionColor, headerColor }) => {
	return(
			// <section className={`${sectionColor} wheretogo-slick`}>
			// 	<Container className="py-5">
			// 		<Row>
			// 			<Col xs={{size: 10, offset: 1}} md={{size: 6, offset: 0}} lg="4" xl="4" className="mb-5">
			// 				<Card inverse className="border-0 mini-map mx-auto"  style={{backgroundColor: 'transparent'}}>
			// 			        <CardImg width="100%" src={dotConfig.appDomain + regionInfo.field_region_map.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt="" />
			// 			        <CardImgOverlay>
			// 			         	<h4 className="font-weight-bold" dangerouslySetInnerHTML={{__html: regionInfo.title}} />
			// 			         	<p className="location position-absolute">{regionInfo.field_region_number}</p>
			// 			        </CardImgOverlay>
			// 			    </Card>
			// 			</Col>
			// 			<Col xs={{size: 10, offset: 1}} md={{size: 6, offset: 0}} lg={{size: 8, offset: 0}} xl={{size: 8, offset: 0}} >
			// 				<CardCarousel nodeIds={regionInfo.field_province_list} />
			// 			</Col>
			// 		</Row>
			// 	</Container>
			// </section>

			<section className={`${sectionColor} wheretogo-slick`}>
				<Container className="pb-5">
					<Row>
						<Col xs>
							<div className="content-sectionheader greenheaderbg text-white pt-3 pb-2 px-3 d-inline-block">
								<h2 className="h3 font-weight-bold" dangerouslySetInnerHTML={{__html: regionInfo.title}} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs className="px-4">
							<CardCarousel regionname={regionInfo.title} nodeIds={regionInfo.field_province_list} />
						</Col>
					</Row>
				</Container>
			</section>

		);
};


export default class WhereToGo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			regionsData:[]
		};
	}

	loadData() {
		// const fetchURL = dotConfig.apiUrl + 'regionlist/?_format=json';
		// const fetchURL = '/data/wheretogo-minimap-temp.json';
		const fetchURL = dotConfig.apiUrl + 'regionlist/';
		fetch(fetchURL)
			.then(response => response.json())
			.then(response => {
				this.setState({
					regionsData: response
				});
			})
			.catch((error) => {
					console.log(error);
			});
	}

	componentDidMount() {
		this.loadData();
	}

	render(){

		var sectionSquence = true;
		var sectionClass = "content-wheretogo-dark";
		// var headerColor = false;
		// console.log(this.state.regionsData)

		return (
				<Fragment>
					<InnerPageBanner id="wheretogo-banner" bg="/images/dot-wheretogo-banner.jpg" title="Swim in A Sea of Clouds" content="<strong>Mt. Pulag,</strong><br/>Benguet"/>
					{/*<TopDestinations />*/}
					{

						this.state.regionsData.map(function (regionData, index) {

							if (regionData.field_province_list === "") {
								return null;
							}

							if (sectionSquence) {
								sectionClass = "content-wheretogo-dark text-white";
								sectionSquence = false;
							} else {
								sectionClass = "content-wheretogo-light text-white";
								sectionSquence = true;
							}

							// if (headerColor) {
							// 	headerColor = "greenheaderbg";
							// } else {
							// 	headerColor = "redheaderbg";
							// }

							// return(<div id={index}><Regions regionInfo={regionData} sectionColor={sectionClass} headerColor={headerColor}/></div>)
							return(
								// <div id={index} key={index}>
								<div id={regionData.field_region_number.replace(/\s/g, '')} key={index}>
									<Regions regionInfo={regionData} sectionColor={sectionClass}/>
								</div>
							)
						})
					}
				</Fragment>
			);
	}
}

