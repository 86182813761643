import React, {Component, Fragment} from 'react';
// import { Card, CardImg, CardImgOverlay } from 'reactstrap';
import { SectionHeader, RenderCarousel, DisplayEvent, LoadMore, InnerPageBanner } from '../components/Commoncomponents';
import { Button, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Row, Col } from 'reactstrap';
import { dotConfig } from '../config';
import { i18n } from './Language';

class FilterMonth extends Component {
	constructor(props) {
	  super(props);
	  this.toggle = this.toggle.bind(this);
	  this.handleScroll = this.handleScroll.bind(this);
	  this.state = {
		dropdownOpen: false,
		value: i18n.__("Select by Month"),
		scrollY: "",
	  };
	}

	componentDidMount() {
	    window.addEventListener('scroll', this.handleScroll);
	  };

	  componentWillUnmount() {
	    window.removeEventListener('scroll', this.handleScroll);
	  };

	  handleScroll(event) {
	    this.setState({
	        scrollY: window.scrollY
	    });

		let sectionsHeight = document.getElementById('dot-navigation').offsetHeight + document.getElementById('festival-banner').offsetHeight;

	    if (window.innerWidth < 992 && this.state.scrollY > sectionsHeight) { 
	        document.getElementById('monthfilter-mobile').classList.add('show-filter');
	    }
	    else{
	        document.getElementById('monthfilter-mobile').classList.remove('show-filter');
	    }
	  };

	setValues(title){
		this.setState({
		    value: title
		 });
	    if (window.innerWidth < 992 ){
	    	window.scrollTo(0, 300);
	    }
	  }

	toggle(event) {
	    this.setState({
	      dropdownOpen: !this.state.dropdownOpen
	    });

	    if (window.innerWidth < 992 && !this.state.dropdownOpen){
	      document.body.classList.add('overlay-active');	        
	    }
	    else {
	      document.body.classList.remove('overlay-active');
	    }
  	}

	render() {
	  return (
		<section className="date-filter">
	   		<ButtonGroup vertical>
				<ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
					<DropdownToggle  className="rounded-0 text-left p-3 select-month">
					{this.state.value} <img src={'/images/icons/dot-festivalicon.png'} className="img-fluid float-right" alt="dateicon"/>
					</DropdownToggle>

		            <DropdownToggle className="border-0 text-left p-3 select-month sticky-filter" id="monthfilter-mobile">
		              <img src={'/images/icons/dot-festivalicon.png'} className="img-fluid float-right" alt="dateicon"/>
		            </DropdownToggle>

					<DropdownMenu className="rounded-0">
						<Button className="float-right pr-2 pt-3 border-0 closeFilter d-lg-none d-xl-none" onClick={this.toggle} style={{width: "initial"}}>&times;</Button>
						<DropdownItem onClick={()=>{this.props.handleClick('showall'); this.setValues('See All')}} className="px-3" >{i18n.__("See All")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('January'); this.setValues('January')}} className="px-3" >{i18n.__("January")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('February'); this.setValues('February')}} className="px-3" >{i18n.__("February")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('March'); this.setValues('March')}} className="px-3" >{i18n.__("March")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('April'); this.setValues('April')}} className="px-3" >{i18n.__("April")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('May'); this.setValues('May')}} className="px-3" >{i18n.__("May")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('June'); this.setValues('June')}} className="px-3" >{i18n.__("June")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('July'); this.setValues('July')}} className="px-3" >{i18n.__("July")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('August'); this.setValues('August')}} className="px-3" >{i18n.__("August")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('September'); this.setValues('September')}} className="px-3" >{i18n.__("September")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('October'); this.setValues('October')}} className="px-3" >{i18n.__("October")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('November'); this.setValues('November')}} className="px-3" >{i18n.__("November")}</DropdownItem>
						<DropdownItem onClick={()=>{this.props.handleClick('December'); this.setValues('December')}} className="px-3" >{i18n.__("December")}</DropdownItem>
					</DropdownMenu>
				</ButtonDropdown>
		  </ButtonGroup>
		</section>
	  );
	}
  }

  class ExploreEvent extends Component{

	constructor(props) {
        super(props);
        this.state = {
         eventContents: [],
         dateFiltered: []
	        };
	    this.filterByDate = this.filterByDate.bind(this);
	    }

	    componentDidMount() {
			const fetchURL = dotConfig.apiUrl + 'festivalsandeventslist/';
	       fetch(fetchURL)
	         .then(response => response.json())
	         .then(response => {
	         this.setState({
	           eventContents: response
	         }, () => {this.filterByDate('showall')});
	        });
	     }

	filterByDate (dateSelect) {
		let { eventContents } = this.state;

		let newDateFilter;

		if (dateSelect === 'showall'){
			newDateFilter = eventContents;
		}
		else{
			newDateFilter = eventContents.filter(data => data.month === dateSelect);
			}

		this.setState({dateFiltered: newDateFilter});
	}

	render(){
		return(
			<section className="content-exploreevent text-white pb-4">
				<Container>
					<Row>
						<Col xs="12" lg="4" xl="3" className="mb-3 d-flex justify-content-center flex-wrap">		
							<FilterMonth handleClick={this.filterByDate} />
						</Col>
						<Col xs="12" lg="8" xl="9"> 
							<LoadMore filteredData={this.state.dateFiltered} page="festivals"/>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

const RenderFestivalsCarousel = ({eventdata}) => {
	var event_content = eventdata.map(function (eventContent, index) {
		   return (<div className="mb-2" key={index}><DisplayEvent eventinfo={eventContent} /></div>)
		})
	return <div><RenderCarousel carouseldata={event_content} /></div>
}


class EventHighlight extends Component {
	constructor(props) {
		super(props);
		this.state = {
			eventContents: []
		};
	}

	loadData() {
    const fetchURL = dotConfig.apiUrl + 'featuredfestivalsandevents/';
    fetch(fetchURL)
      .then(response => response.json())
      .then(response => {
        this.setState({
          eventContents: response
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

	componentDidMount() {
		this.loadData();
	}

	render() {

	return(
		<section className="text-white" id="content-festivalhigh">
			<Container className="pb-5">
				<Row>
					<Col>
						<SectionHeader title={i18n.__("Top Festivals")} bgcolor="redheaderbg"/>
					</Col>
				</Row>
				<Row>
					<Col className="px-4">
						<RenderFestivalsCarousel eventdata={this.state.eventContents} />
					</Col>
				</Row>
			</Container>
		</section>
		);
	}
};

export default class Festivals extends Component{
	render(){
		return (
			<Fragment>
				<InnerPageBanner id="festival-banner" bg="/images/dot-festivals-banner.jpg" title={i18n.__("Hermosa Festival")} content="Zamboanga City"/>
				<ExploreEvent />
				<EventHighlight />
				{/* <News /> */}
			</Fragment>
		);
	}
}

