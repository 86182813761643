import React, { Component } from 'react';
import { RenderContent, WhereToStayLayout, WhatsNearLayout, RenderCarousel } from '../components/Commoncomponents';
import { dotConfig } from '../config';
import { Container, Row, Col, Card, CardHeader, Collapse, CardBody, CardImg, CardImgOverlay, Button } from 'reactstrap';
import { Link } from "react-router-dom";

export class WhereToStay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accomodationData:[],
            nodeIds: this.props.nodeIds
        }
    }

    static getDerivedStateFromProps(nextProps, prevProps) {
        if (nextProps.nodeIds !== prevProps.nodeIds) {
            return {
                nodeIds: nextProps.nodeIds
            }
        }
        return null;
    }

    loadData() {
        const fetchURL = dotConfig.apiUrl + 'accomodations/' + encodeURI(this.state.nodeIds.replace(/\s/g, '')) + '?_format=json';
        fetch(fetchURL)
        .then(response => response.json())
        .then(response => {
          this.setState({
            accomodationData: response
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    componentDidMount() {
        if (this.state.nodeIds.length > 0) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if (this.state.nodeIds !== prevState.nodeIds) {
            if (this.state.nodeIds.length > 0) {
                this.loadData();
            }
        }
    }

    render() {
        const { accomodationData } = this.state;

        if (this.state.nodeIds.length > 0) {
            if (accomodationData.length === 0) {
                return <div><p>Loading...</p></div>;
            }

            return (
                // <section className="lightgreybg">
                    <Container className="pb-4">
                        <Row>
                            <Col className="my-3">
                                <RenderContent className="font-weight-bold pastelbluebg py-3 px-3 mb-2 h5 text-white">
                                Where to Stay
                                </RenderContent>
                            </Col>
                        </Row>
                        <Row className="pt-3 pb-4">
                            <Col>
                                <WhereToStayLayout locations={accomodationData} />
                            </Col>
                        </Row>
                    </Container>
                // </section>
            );
        }
        return null;

    }
};

// ----------------------------------------------------------------------------------------

export class WhatsNearFold extends Component {
    constructor(props) {
      super(props);
      this.state = {
        fetchedContents: [],
        nodeIds: this.props.nodeIds,
        section: this.props.section,
        isLoading: true
      };
    }

    loadData() {
        let fetchURL;
        
        if (this.state.section == "activities") {
          fetchURL = dotConfig.apiUrl + 'activitiesnearme/' + encodeURI(this.state.nodeIds.replace(/\s/g, ''));
        } else {
          fetchURL = dotConfig.apiUrl + 'nearme/' + encodeURI(this.state.nodeIds.replace(/\s/g, ''));
        }

        fetch(fetchURL)
        .then(response => response.json())
        .then(response => {
          this.setState({
            fetchedContents: response,
            isLoading: false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.nodeIds !== prevState.nodeIds) {
          return {
            nodeIds: nextProps.nodeIds
          }
        }
        return null;
      }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.nodeIds !== prevState.nodeIds) {
          this.loadData();
        }
      }

    render() {
      if (this.state.fetchedContents.length > 0) {
        return (
            <Container id="whatsnear">
              <Row>
                  <Col className="mt-3 mb-4">
                      <RenderContent className="lightbluebg font-weight-bold py-3 px-3 mb-2 h5 text-white">
                      What's Near
                      </RenderContent>
                  </Col>
              </Row>
              <Row className="pt-3 pb-5">
                  <Col className="px-4">
                    <WhatsNearLayout nearLocations={this.state.fetchedContents} />
                  </Col>
              </Row>
          </Container>
          );
      } else if (this.state.isLoading) {
        return(
          <p>Loading...</p>
        )
      } else {
        return(null)
      }
    }
  }

// module.exports = WhereToStay;

export class DestinationsAccordion extends Component{
  constructor(props) {
      super(props);
      this.state = {
        collapse: "",
      };
      this.toggle = this.toggle.bind(this);
  }

  toggle = collapse => () => {
      this.setState({ 
          collapse: this.state.collapse === collapse ? "" : collapse,
      }); 
  }

  toggleButton (state, condition, color){
    if (state === condition){
        return (
            <span className={color === 'black' ? 'position-absolute accordion-arrows' :  'position-absolute accordion-arrows text-white'}>&#9650;</span>
        )
    }
    else{
        return (
            <span className={color === 'black' ? 'position-absolute accordion-arrows' :  'position-absolute accordion-arrows text-white'}>&#9660;</span>
        )
    }
  }

  render(){   
    if (!this.props.accordion){
        return <div>Loading</div>
    }
    return (
      <div className="mb-4">
        {
          this.props.accordion.map((info, index) => {
            return (
              
              this.props.type === 'faq'
              ?
              <Card className="text-white" style={{ marginBottom: '0.5rem'}} key={index} id="destinationfaq-card">
                <CardHeader onClick={this.toggle(`collapse${index}`)} data-event={index} style={{cursor: 'pointer'}}>
                    <span dangerouslySetInnerHTML={{__html: info.title}} />
                    {this.toggleButton(this.state.collapse, `collapse${index}`)}
                </CardHeader>
                <Collapse isOpen={this.state.collapse === `collapse${index}`}>
                    <CardBody className="pt-3 pb-2" dangerouslySetInnerHTML={{__html: info.content}} />
                </Collapse>
              </Card>
              :
              <Card style={{ marginBottom: '0.5rem'}} key={index} >
                <CardHeader onClick={this.toggle(`collapse${index}`)} data-event={index} style={{cursor: 'pointer'}}>{info.header}
                    {this.toggleButton(this.state.collapse, `collapse${index}`, 'black')}
                </CardHeader>
                <Collapse isOpen={this.state.collapse === `collapse${index}`}>
                  {
                    info.contents.map(content => {
                      return <CardBody key={content.title} className="pt-3 pb-2" dangerouslySetInnerHTML={{__html: content.title}} />
                    })
                  }
                </Collapse>
              </Card>
            )
          })
        }
      </div>
    );
  }
}

// --------------- REGIONS PAGE COMPONENTS -------------------------------------------------------------------------

export function RegionDestionations ({destinationssection}){
  return(
    <>
      {
        destinationssection.map((destination, index) => {
          const linkArray = destination.url_alias.split('/');
          const destinationLink = "/activities/" + linkArray[linkArray.length - 1];
          return (
            <Row className="mb-4 mb-lg-5" key={index}>
              <Col xs="12" lg={{size: 6, order: index % 2 === 0 ? 0 : 2}}>
                <img src={dotConfig.appDomain + destination.field_region_feature_image.replace(/\\\//g, "/")} alt={destination.title} className="img-fluid mx-auto d-block"/>
              </Col>
              <Col xs="12" lg="6" className="mt-3">
                <div className="absolute-center">
                  <h4 className="font-weight-bold" dangerouslySetInnerHTML={{__html: destination.title}}/>
                  <p dangerouslySetInnerHTML={{__html: destination.field_description}}/>
                  <Link to={destinationLink}><Button className="btn-black-outline">Read more</Button></Link>
                </div>
              </Col>
            </Row>
          )
        })
      }
    </>
  )
}

export function RegionProvinceTiles ({provincesection}){
  return(
    <>
      {
        provincesection.map((province) => {
          const linkArray = province.url_alias.split('/');
          const provinceLink = "/destinations/" + linkArray[linkArray.length - 1];
          return (
            <Col xs="12" md={{size: 8, offset: 2}} lg={{size: 6, offset: 0}} className="mb-5" key={province.title}>
              <Link to={provinceLink}>
                <Card inverse className="border-0 card-image-overlay">
                  <CardImg width="100%" src={dotConfig.appDomain + province.field_region_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt={province.title} />
                  <CardImgOverlay className="regionprovincetiles">
                    <h4 className="font-weight-bold mb-3" dangerouslySetInnerHTML={{__html: province.title}} />
                    <p dangerouslySetInnerHTML={{__html: province.field_description}} />
                    <Button outline className="btn-white text-white button-bottom">Read more</Button>
                  </CardImgOverlay>
                </Card>
              </Link>
            </Col>
          )
        })
      }
    </>
  )
}

export function RegionFestivalsTiles ({festivalssection}){
  return(
    <>
      {
        festivalssection.map(festival => {
          const linkArray = festival.url_alias.split('/');
          const eventLink = "/events/" + linkArray[linkArray.length - 1];
          return (
            <Col xs="12" md="6" xl="4" className="mb-5" key={festival.title}>
              <Link to={eventLink}>
                <Card inverse className="border-0 card-image-overlay">
                  <CardImg width="100%" src={dotConfig.appDomain + festival.field_region_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt={festival.title} />
                  <CardImgOverlay>
                    <h4 className="font-weight-bold mb-3" dangerouslySetInnerHTML={{__html: festival.title}} />
                    <p className="font-weight-bold mb-3" dangerouslySetInnerHTML={{__html: '(' + festival.field_event_date + ', ' + festival.field_event_province + ')'}} />
                    <p className="font-small" dangerouslySetInnerHTML={{__html: festival.field_description}} />
                  </CardImgOverlay>
                </Card>
              </Link>
            </Col>
          )
        })
      }
    </>
  )
}

export const NearbyRegionsTiles = ({ nearbyregions }) => {
  var car_content = nearbyregions.map(region => {
      return (
    
          <Link to={region.link} key={region.title}>
            <Card inverse className="border-0 card-image-overlay">
              <CardImg width="100%" src={region.img} className="img-fluid rounded-0" alt=""/>
              <CardImgOverlay>
                <h4 className="font-weight-bold text-bottom" dangerouslySetInnerHTML={{__html: region.title}}/>
              </CardImgOverlay>
            </Card>
          </Link>
        
      );
    })
  return (<RenderCarousel carouseldata={car_content} />)
};
