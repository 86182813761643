import React, { Component } from 'react';
import 
  // ScrollableAnchor, 
  { configureAnchors } 
from 'react-scrollable-anchor';
import { 
  // Button,
  // ButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  Container,
  Row,
  Col } from 'reactstrap';
import { SubSection, RenderContent, UpcomingEventsAndFestivalsLayout, Banner } from '../components/Commoncomponents';
import { WhereToStay } from './Commoncontainers'
// import { HashLink as Link } from 'react-router-hash-link';
import { dotConfig } from '../config';

configureAnchors({ offset: -50 })

class UpcomingEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsData: this.props.eventsdata,
      // nodeId: this.props.nodeId
    }
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.eventsdata !== prevProps.eventsdata) {
      return {
        eventsData: nextProps.eventsdata
      }
    }
  }

  // loadData() {
  //   const fetchURL = dotConfig.apiUrl + 'upcomingevents/' + this.state.nodeId + '?_format=json';
  //   fetch(fetchURL)
  //     .then(response => response.json())
  //     .then(response => {
  //       this.setState({
  //         eventsData: response
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // componentDidMount() {
  //   this.loadData();
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.nodeId !== prevState.nodeId) {
  //     this.loadData();
  //   }
  // }

  render() {
    const { eventsData } = this.state;
    // console.log(eventsData);
    if (eventsData.length === 0) {
      return <div><p>Loading...</p></div>;
    }
    return (
      <Container>
        <Row>
          <Col className="mb-3">
            <RenderContent className="font-weight-bold redbg py-3 px-3 mb-2 h5 d-inline-block text-white">
              Upcoming Events and Festivals
            </RenderContent>
          </Col>
        </Row>
        <Row className="pt-1 pb-5 festival-info">
          <Col className="px-4">
            <UpcomingEventsAndFestivalsLayout upcomingEvents={eventsData} />
          </Col>
        </Row>
      </Container>
    );
  }
}

class DotEvents extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      festivalData: [],
      eventsData: [],
      eventNode: this.props.match.params.nodeid,
      dropdownOpen: false
    }
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
    if (!this.state.dropdownOpen) {
      document.body.classList.add('overlay-active');
    } else {
      document.body.classList.remove('overlay-active');
    }
  }
  loadData() {
    // console.log('Loading data!');
    // const fetchURL = dotConfig.apiUrl + 'festivalsandevents/' + this.state.eventNode + '?_format=json';
    // const linkArray = nearLocation.url_alias.split('/');
    const fetchURL = dotConfig.apiUrl + 'festivalsandevents?alias=/events/' + this.state.eventNode;
    fetch(fetchURL)
      .then(response => response.json())
      .then(response => {
        this.setState({
          festivalData: response
        });

        // Fetch Upcoming Events      
        const upcomingFetchURL = dotConfig.apiUrl + 'upcomingevents/' + response[0].nid;
        fetch(upcomingFetchURL)
        .then(upcomingresponse => upcomingresponse.json())
        .then(upcomingresponse => {
          console.log(upcomingresponse);
          this.setState({
            eventsData: upcomingresponse
          });
        })
        .catch((error) => {
          console.log(error);
        });

      })
      .catch((error) => {
        console.log(error);
      });

    // // Fetch Upcoming Events      
    // const upcomingFetchURL = dotConfig.apiUrl + 'upcomingevents/' + this.state.nodeId;
    // fetch(upcomingFetchURL)
    //   .then(response => response.json())
    //   .then(response => {
    //     this.setState({
    //       eventsData: response
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.nodeid !== prevState.eventNode) {
      // console.log('Props change!!!');
      return {
        eventNode: nextProps.match.params.nodeid
      }
    }
    return null;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.eventNode !== prevState.eventNode) {
      // console.log('Reload Data!!!');
      this.loadData();
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { festivalData, eventsData } = this.state;
    const bgArray = ["lightgreybg", "darkgreybg", ""];
    var bgCounrter = 0;
    // console.log(festivalData);
    if (festivalData.length === 0) {
      return <div>
        <p>Loading...</p>
      </div>;
    } else {
      var festivalDisplayData = festivalData[0];
    }

    //Destination link
    // const destinationLink = '/destinations/' + festivalDisplayData.field_event_province_id;
    const background = `${dotConfig.appDomain}${festivalDisplayData.field_main_banner.replace(/\\\//g, "/")}`;
    return (
      <div id="dotevents">
        <Banner background={background} bannertext={festivalDisplayData.title} />
        <Container>
          <Row>
            <Col xs="12" >
              <h5>
                <RenderContent className="font-weight-bold redbg py-3 px-3 d-inline-block text-white" dangerouslySetInnerHTML={{ __html: festivalDisplayData.title }} />
              </h5>
              <Row>
                <Col lg={{size: 10, offset: 1}} className="text-center">
                  <article className="pt-4 pb-5 pl-3" style={{ color: '#4b4b4b' }}>
                    <RenderContent className="d-block">
                      <div style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: festivalDisplayData.field_description }} />
                    </RenderContent>
                    <SubSection header="How to get there:" body={festivalDisplayData.field_how_to_get_there} />
                    <SubSection header="What to Wear:" body={festivalDisplayData.field_what_to_wear} />
                  </article>
                
                </Col>
              </Row>
            </Col>
            {/* <Col className="d-none d-lg-block" lg="6">
              <img
                src="/images/dot-banig-large.jpg"
                className="mx-auto img-fluid d-block"
                alt="dot-banig-large"
              />
            </Col> */}
          </Row>
        </Container>
        {festivalDisplayData.field_where_to_stay.length > 0 && 
          <section className={bgArray[bgCounrter++]}>
            <WhereToStay nodeIds={festivalDisplayData.field_where_to_stay} />
          </section>
        }

        {eventsData.length > 0 && 
          <section className={bgArray[bgCounrter++]}>
            <UpcomingEvents eventsdata={eventsData} />
          </section>
        }

            {/*<article className="anchor-fab d-lg-none">
              <ButtonDropdown direction="up" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle style={{ opacity: '1' }} className="sticky-filter">
                  <i class="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                  <div className="clearfix">
                    <Button className="float-right pr-3 py-0 border-0 closeFilter" onClick={this.toggle}>&times;</Button>
                  </div>
                  <br />
                  <h3 className="text-center text-white">{festivalDisplayData.title}</h3>
                  <a href="#wheretostay">
                    <DropdownItem>
                      <h5 className="text-white">Where to Stay </h5>
                    </DropdownItem>
                  </a>
                  <Link to={destinationLink}>
                    <DropdownItem>
                      <h5 className="text-white">Explore {festivalDisplayData.field_event_province}</h5>
                    </DropdownItem>
                  </Link>
                  <a href="#upcomingevents">
                    <DropdownItem>
                      <h5 className="text-white">Upcoming Events and Festivals</h5>
                    </DropdownItem>
                  </a>
                </DropdownMenu>
              </ButtonDropdown>
            </article>*/}

{/*            <div className="d-none d-lg-flex col-lg-6 flex-column align-items-end">
              <a href="#wheretostay">
                <article className="position-relative">
                  <h2 className="text-white anchor-text">Where to <br /> stay</h2>
                  <img className="img-fluid" alt="dot anchor where to stay" src='/images/dot-anchor-events-where-to-stay.jpg' />
                  <img className="anchor-image" alt="dot anchor button" src="/images/dot-anchor-button.png" />
                </article>
              </a>
              <article className="position-relative">
                <Link to={destinationLink}>
                  <h2 className="text-white anchor-text">Explore {festivalDisplayData.field_event_province}</h2>
                  <img className="img-fluid" alt="dot anchor events explore" src='/images/dot-anchor-events-explore.jpg' />
                  <img className="anchor-image" alt="dot anchor button" src="/images/dot-anchor-button.png" />
                </Link>
              </article>
              <a href="#upcomingevents">
                <article className="position-relative">
                  <h2 className="text-white anchor-text">Upcoming Events and Festivals</h2>
                  <img className="img-fluid" alt="dot anchor events and festival" src='/images/dot-anchor-seeanddo-where-to-stay.jpg' />
                  <img className="anchor-image" alt="dot anchor button" src="/images/dot-anchor-button.png" />
                </article>
              </a>
            </div>*/}

         
{/*
        <ScrollableAnchor id={'wheretostay'}>
          <section className="lightgreybg">
            <WhereToStay nodeIds={festivalDisplayData.field_where_to_stay} />
          </section>
        </ScrollableAnchor>
        <ScrollableAnchor id={'upcomingevents'}>
          <section className="darkgreybg">
            <UpcomingEvents nodeId={festivalDisplayData.nid} />
          </section>
        </ScrollableAnchor>*/}
      </div>

    );
  }
}

export default DotEvents;
