import React, { Fragment, Component } from "react";

import { dotConfig } from "../config";
import { Link as RRLink } from "react-router-dom";
import { HashLink as AnchorLink } from "react-router-hash-link";
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledAlert,
  // Card, 
  // CardTitle, 
  // CardImg, 
  // CardImgOverlay
} from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ExploreMap from "./ExploreMap";

import { RenderImage, RenderContent } from "../components/Commoncomponents";
import { i18n } from './Language';


function COVIDAlert() {
  return (
    <UncontrolledAlert fade={false} className="mb-0 pt-3 pb-0 px-0 border-0 rounded-0 alert-banner" color="primary">
      <Container>
        <Row>
          <Col xl={{size: 10, offset: 1}} >
            <p style={{fontSize: '13px'}}>{i18n.__("In light of the current COVID-19 situation, the Philippines has implemented temporary travel restrictions to contain the spread of COVID-19. We urge you to stay home and follow community quarantine protocols.")} <RRLink to="/safeph" style={{textDecoration: 'underline', color: '#004085'}} className="font-weight-bold">{i18n.__("Learn more.")}</RRLink></p>
          </Col>
        </Row>
      </Container>
    </UncontrolledAlert>
  );
}

const SeeAndDoActivitiesLayout = ({ data }) => {
  return (
    <Col
      xs="4"
      lg="2"
      style={{
        background: "url(" + data.bgimg + ") center center / cover no-repeat"
      }}
      className={`text-center p-0 seeanddo-tiles card-image-overlay ${data.colors}`}
    >
      <AnchorLink 
        to={`/seeanddo#${data.nid}`}
        className="activitiesTiles"
        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center'})}
      >
        <RenderImage src={data.src} />
        <h5 className="text-light pt-2 pl-2">
          <RenderContent>{data.content}</RenderContent>
        </h5>
      </AnchorLink>
    </Col>
  );
};


const RenderActivities = ({ activities }) => {
  return activities.map(activity => {
    return <SeeAndDoActivitiesLayout data={activity} key={activity.colors} />;
  });
};

class SeeAndDoActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // navAffix: "",
      // affixMargin: "0px",
      // throttled: false,
      fetchedContents: [
        {
          src: "/images/dot-category-dive.png",
          colors: "seeanddo-category-dive",
          bgimg: "/images/dot-category-dive-bg.jpg",
          content: i18n.__("Dive"),
          nid: "dive"
        },
        {
          src: "/images/dot-category-nature.png",
          colors: "seeanddo-category-nature",
          bgimg: "/images/dot-category-nature-bg.jpg",
          content: i18n.__("Nature"),
          nid: "nature"
        },
        {
          src: "/images/dot-category-sunbeach.png",
          colors: "seeanddo-category-sunbeach",
          bgimg: "/images/dot-category-sunbeach-bg.jpg",
          content: i18n.__("Sun and Beach"),
          nid: "sunandbeach"
        },
        {
          src: "/images/dot-category-adventure.png",
          colors: "seeanddo-category-adventure",
          bgimg: "/images/dot-category-adventure-bg.jpg",
          content: i18n.__("Adventure"),
          nid: "adventure"
        },
        {
          src: "/images/dot-category-foodhospitality.png",
          colors: "seeanddo-category-foodhospitality",
          bgimg: "/images/dot-category-foodhospitality-bg.jpg",
          content: i18n.__("Food and Hospitality"),
          nid: "foodandhospitality"
        },
        {
          src: "/images/dot-category-eventsculture.png",
          colors: "seeanddo-category-eventsculture",
          bgimg: "/images/dot-category-eventsculture-bg.jpg",
          content: i18n.__("Events and Culture"),
          nid: "eventsandculture"
        }
      ]
    };
    // this.handleScroll = this.handleScroll.bind(this);
  }

  // componentDidMount() {
  //   window.addEventListener("scroll", this.handleScroll);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // }

  // handleScroll(event) {
  //   if (!this.state.throttled) {
  //     // actual callback action
  //     this.forceUpdate()
  //     // we're throttled!
  //     // throttled = true;
  //     this.setState({
  //       throttled: true
  //     })
  //     // set a timeout to un-throttle
  //     setTimeout(() => {
  //       this.setState({
  //         throttled: false
  //       })
  //     }, 500);
  //   }

  //   if (window.scrollY > document.getElementById("homepage-carousel").offsetHeight) {
  //     this.setState({
  //       navAffix: "navaffix",
  //     });

  //     if (window.innerWidth > 991 && window.innerWidth < 1199) {
  //       this.setState({
  //         affixMargin: "46px"
  //       });
  //     }
  //     else {
  //       this.setState({
  //         affixMargin: "70px"
  //       });
  //     }

  //   }
  //   else {
  //     this.setState({
  //       navAffix: "",
  //       affixMargin: "0px"
  //     });
  //   }
  // }

  render() {
    return (
      <Fragment>
        {/* <Container
          className={`seeanddo-category d-none d-lg-block ${
            this.state.navAffix
          }`}
          style={{ marginTop: this.state.affixMargin }}
        > */}
        <Container
          className="seeanddo-category d-none d-lg-block">
          <Row>
            <RenderActivities activities={this.state.fetchedContents} />
          </Row>
        </Container>
        <Container fluid className="seeanddo-category d-block d-lg-none">
          <Row>
            <RenderActivities activities={this.state.fetchedContents} />
          </Row>
        </Container>
      </Fragment>
    );
  }
}


class SeeAndDoBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedContents: []
    };
  }

  componentDidMount() {
    // const fetchURL = 'http://beta.tribalddbph.com/Jeff/dot/json/see-and-do.json';
    const fetchURL = dotConfig.apiUrl + 'homemainbanners/';
    // const fetchURL = "http://dotbeta.tribalph.com/cms/homemainbanners";
    fetch(fetchURL)
      .then(response => response.json())
      .then(response => {
        this.setState({
          fetchedContents: response
        });
      });
  }

  render() {
    const { fetchedContents } = this.state;

    if (!fetchedContents) {
      return <div> loading </div>;
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
      // arrows: false
    };
    
    return (
      <Fragment>
        <div className="homepage-carousel">
          <Slider {...settings}>
         
            {fetchedContents.map(fetchedContent => {
              return (
                <Fragment key={fetchedContent.title}>
                  {
                    fetchedContent.ctaLink !== ""
                    ?
                      fetchedContent.linkType === "ext" 
                      ? 
                        <a
                          color="link"
                          href={fetchedContent.ctaLink}
                          style={{textDecoration: 'none'}}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                        <div
                            className="d-flex homebannerstyle align-items-center"
                            style={{
                              background: `url(${
                                dotConfig.appDomain
                              }${fetchedContent.src.replace(
                                /\\\//g,
                                "/"
                              )}) top center no-repeat`,
                              backgroundSize: "cover"
                            }}
                          >
                            <Container>
                              <Row>
                                <article>
                                  {
                                    fetchedContent.field_hide_title === 'True'
                                    ?
                                      ""
                                    :
                                      <h1
                                        className="text-white display-3 font-weight-bold"
                                        dangerouslySetInnerHTML={{
                                          __html: fetchedContent.title
                                        }}
                                      />
                                  }
                                  
                                  {
                                    fetchedContent.location !== ""
                                    ?
                                      <div className="d-flex">
                                        <img
                                          src="/images/dot-home-banner-icon.png"
                                          className="align-self-center"
                                          alt="dot-home-banner-icon"
                                        />
                                        <p
                                          className="text-white pl-2 mb-0"
                                          dangerouslySetInnerHTML={{
                                            __html: fetchedContent.location
                                          }}
                                        />
                                      </div>
                                    :
                                    ""
                                  }

                                  {
                                    fetchedContent.field_hide_title === 'True'  
                                    ?
                                      ""
                                    :
                                    <Button
                                      color="link"
                                      className="ghost-white mt-3"
                                      style={{textDecoration: 'none'}}
                                    >
                                      Read more
                                    </Button>
                                  }
                                </article>
                              </Row>
                            </Container>
                          </div>
                        </a>
                      : 
                        <RRLink
                          color="link"
                          to={fetchedContent.ctaLink}
                          style={{textDecoration: 'none'}}
                        >
                          <div
                            className="d-flex homebannerstyle align-items-center"
                            style={{
                              background: `url(${
                                dotConfig.appDomain
                              }${fetchedContent.src.replace(
                                /\\\//g,
                                "/"
                              )}) top center no-repeat`,
                              backgroundSize: "cover"
                            }}
                          >
                            <Container>
                              <Row>
                                <article>
                                {
                                    fetchedContent.field_hide_title === 'True'
                                    ?
                                      ""
                                    :
                                      <h1
                                        className="text-white display-3 font-weight-bold"
                                        dangerouslySetInnerHTML={{
                                          __html: fetchedContent.title
                                        }}
                                      />
                                  }
                                  {
                                    fetchedContent.location !== ""
                                    ?
                                      <div className="d-flex">
                                        <img
                                          src="/images/dot-home-banner-icon.png"
                                          className="align-self-center"
                                          alt="dot-home-banner-icon"
                                        />
                                        <p
                                          className="text-white pl-2 mb-0"
                                          dangerouslySetInnerHTML={{
                                            __html: fetchedContent.location
                                          }}
                                        />
                                      </div>
                                    :
                                    ""
                                  }
                                  {
                                    fetchedContent.field_hide_title === 'True'  
                                    ?
                                      ""
                                    :
                                    <Button
                                      color="link"
                                      className="ghost-white mt-3"
                                      style={{textDecoration: 'none'}}
                                    >
                                      Read more
                                    </Button>
                                  }
                                  
                                </article>
                              </Row>
                            </Container>
                          </div>
                        </RRLink>
                    :
                    <div
                      className="d-flex homebannerstyle align-items-center"
                      style={{
                        background: `url(${
                          dotConfig.appDomain
                        }${fetchedContent.src.replace(
                          /\\\//g,
                          "/"
                        )}) top center no-repeat`,
                        backgroundSize: "cover"
                      }}
                    >
                      <Container>
                        <Row>
                          <article>
                            {
                              fetchedContent.field_hide_title !== 'False'
                              ?
                                <h1
                                  className="text-white display-3 font-weight-bold"
                                  dangerouslySetInnerHTML={{
                                    __html: fetchedContent.title
                                  }}
                                />
                              :
                              ""
                            }
                            
                            {
                              fetchedContent.location !== ""
                              ?
                                <div className="d-flex">
                                  <img
                                    src="/images/dot-home-banner-icon.png"
                                    className="align-self-center"
                                    alt="dot-home-banner-icon"
                                  />
                                  <p
                                    className="text-white pl-2 mb-0"
                                    dangerouslySetInnerHTML={{
                                      __html: fetchedContent.location
                                    }}
                                  />
                                </div>
                              :
                              ""
                            }
                          </article>
                        </Row>
                      </Container>
                    </div>
                  }
                </Fragment>
              );
            })}

            {/* <a href="/data/PHTravelSurveyReport2020.pdf" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}} download>
              <div className="d-flex homebannerstyle" style={{background: "url(/images/dot-homepage-PHTravelSurvey.jpg) top center no-repeat", backgroundSize: "cover"}}/>
            </a> */}
          </Slider>
        </div>
        <SeeAndDoActivities />
      </Fragment>
    );
  }
}


export default class Home extends React.Component {
  render() {
    return (
      <Fragment>
        <COVIDAlert/>
        <div className="position-relative">
          <SeeAndDoBanner />
        </div>
        <ExploreMap />
      </Fragment>
    );
  }
}
