import React, { Component, Fragment } from "react";
// import { Route } from "react-router-dom";
// import { Logo } from '../common';
// import { RenderImage } from "../components/Commoncomponents";
import { Logo } from "../components/Commoncomponents";
import { HashLink as Link } from "react-router-hash-link";
import isMobile from 'ismobilejs';
import { i18n } from './Language';


const RenderCountries = ({ links }) => {
  return links.map(link => {
    return (
      <article className="pt-2 pt-lg-0 d-flex align-items-center justify-content-center justify-content-lg-start" key={link.name}>
        <a className="d-flex align-items-center" href={link.url} key={link.name} target="_blank" rel="noopener noreferrer">
            <img className="pr-2 img-fluid" src={link.img} alt={link.name}/>
            <p className="text-white mb-2 footer-links">{link.name}</p>
        </a>
      </article>
    );
  });
};

const RenderFooterContent = ({ links }) => {
  return links.map(link => {
    if (link.type === "ext") {
      return (
        <a href={link.url} key={link.name} target="_blank" rel="noopener noreferrer">
          <p key={link.name} className="text-white mb-2 footer-links">
            {link.name}
          </p>
        </a>
      );
    } else {
      return (
        <Link to={link.url} key={link.name}>
          <p key={link.name} className="text-white mb-2 footer-links">
            {link.name}
          </p>
        </Link>
      );
    }
  });
};


class Countries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [
        {
          name: i18n.__("Australia"),
          url: "https://www.tourismphilippines.com.au/",
          img: "/images/flags/australia.png"
        },
        {
          name: i18n.__("China"),
          url: "http://www.morefun-philippines.com/",
          img: "/images/flags/china.png"
        },
        {
          name: i18n.__("Germany"),
          url: "http://www.morefunphilippines.de",
          img: "/images/flags/germany.png"
        },
        {
          name: i18n.__("Japan"),
          url: "https://philippinetravel.jp",
          img: "/images/flags/japan.png"
        },
        {
          name: i18n.__("Korea"),
          url: "https://www.itsmorefuninthephilippines.co.kr/",
          img: "/images/flags/south-korea.png"
        },
        {
          name: i18n.__("Russia"),
          url: "https://philippines.profi.travel/",
          img: "/images/flags/russia.png"
        },
        {
          name: i18n.__("Taiwan"),
          url: "http://www.itsmorefuninthephilippines.com.tw",
          img: "/images/flags/taiwan.png"
        },
        {
          name: i18n.__("UK"),
          url: "http://www.itsmorefuninthephilippines.co.uk",
          img: "/images/flags/united-kingdom.png"
        },
        {
          name: i18n.__("USA"),
          url: "http://www.philippinetourismusa.com",
          img: "/images/flags/united-states-of-america.png"
        },
      ]
    };
  }

  render() {
    return(
      <Fragment>
        <p className="text-white font-weight-bold">{i18n.__("FOREIGN OFFICES")}</p>
        <RenderCountries links={this.state.countries} />
      </Fragment>
    )
  }
}

// const RenderDotDirectory = ({ links }) => {
//   return links.map(fetchedContent => {
//     return (
//       <Link to={fetchedContent.url} key={fetchedContent.name}>
//         <p key={fetchedContent.name} className="text-white font-weight-bold">
//           {fetchedContent.name}
//         </p>
//       </Link>
//     );
//   });
// };

// class DotDirectories extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       DotDirectories: [
//         // {
//         //   "name": "DOT DIRECTORY",
//         //   "url": "https://www.facebook.com/DepartmentOfTourism/",
//         //   "type": "ext"
//         // },
//         {
//           name: "PRIVACY POLICY",
//           url: "/",
//           type: "ext"
//         }
//         // {
//         //   "name": "SITEMAP",
//         //   "url": "https://www.youtube.com/user/morefunph",
//         //   "type": "ext"
//         // }
//       ]
//     };
//   }

//   render() {
//     return <RenderDotDirectory links={this.state.DotDirectories} />;
//   }
// }

class SocialMedia extends Component {
  constructor(props) {
    super(props);
    // this.checkMobileDevice = this.checkMobileDevice.bind(this);
    this.state = {
      socialLinks: [
        {
          name: "DOT Facebook",
          url: "https://www.facebook.com/DepartmentOfTourism/",
          androidlink: "fb://page/197186436974903",
          ioslink: "fb://profile/197186436974903"
        },
        {
          name: "Facebook",
          url: "https://www.facebook.com/itsmorefuninthePhilippines",
          androidlink: "fb://page/240054519398747",
          ioslink: "fb://profile/240054519398747"
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/tourism_phl",
          androidlink: "http://instagram.com/_u/tourism_phl",
          ioslink: "instagram://user?username=tourism_phl"
        },
        {
          name: "Youtube",
          url: "https://www.youtube.com/user/morefunph",
          androidlink: "vnd.youtube:///user/morefunph",
          ioslink: "vnd.youtube:///user/morefunph"
          // ioslink: "youtube://www.youtube.com/channel/UC_yCORmIAJntXQDSHs4iFcw"
        }
      ]
    };
  }

  render() {
    return (
      <span>
        <p className="text-white font-weight-bold">{i18n.__("SOCIAL MEDIA")}</p>
        {
          this.state.socialLinks.map(link => {
            if (isMobile(navigator.userAgent).apple.device){
              return (
                <a href={link.ioslink} key={link.name}>
                   <p className="text-white mb-2 footer-links"> 
                     {link.name}
                   </p>
                 </a>
              )
            }
            else if (isMobile(navigator.userAgent).android.device){
              return (
                <a href={link.androidlink} key={link.name}>
                   <p className="text-white mb-2 footer-links"> 
                     {link.name}
                   </p>
                 </a>
              )
            }
            else{
              return (
                <a href={link.url} key={link.name} target="_blank" rel="noopener noreferrer">
                   <p className="text-white mb-2 footer-links"> 
                     {link.name}
                   </p>
                 </a>
              )
            }        
          })
        }
      </span>
    );
  }
}



class GovernmentAgencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      governmentLinks: [
        {
          name: "TIEZA",
          url: "http://www.tieza.gov.ph",
          type: "ext"
        },
        {
          name: "TPB",
          url: "http://www.tpb.gov.ph",
          type: "ext"
        },
        // {
        //   name: "NPDC",
        //   url: "http://www.npdc.gov.ph/",
        //   type: "ext"
        // },
        {
          name: "IA",
          url: "http://www.intramuros.gov.ph/",
          type: "ext"
        },
        {
          name: "DFP",
          url: "http://www.dfp.com.ph",
          type: "ext"
        },
        {
          name: "PCSSD",
          url: "http://www.divephilippines.com.ph",
          type: "ext"
        },
        {
          name: "PRA",
          url: "http://www.pra.gov.ph",
          type: "ext"
        }
      ]
    };
  }

  render() {
    return (
      <Fragment>
        <p className="text-white font-weight-bold">{i18n.__("GOVERNMENT AGENCIES")}</p>
        <RenderFooterContent links={this.state.governmentLinks} />
      </Fragment>
    );
  }
}

class Tourisms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tourismLinks: [
        // {
        //   name: "About the Philippines",
        //   url: "http://tourism.gov.ph/history.aspx",
        //   type: "ext"
        // },
        // {
        //   name: "Leisure",
        //   url: "/"
        // },
        {
          name: i18n.__("Business"),
          url: "http://tourism.gov.ph/",
          type: "ext"
        },

        // {
        //   name: "Search",
        //   url: "/"
        // }
      ]
    };
  }

  render() {
    return (
      <span>
        <p className="text-white font-weight-bold">{i18n.__("TOURISM")}</p>
        <RenderFooterContent links={this.state.tourismLinks} />
      </span>
    );
  }
}

class FooterSection extends Component {
  render() {
    return (
      <section className="greybg" id="footerlinks">
        <div className="container">
          <div className="row py-4">
            <div className="text-center text-lg-left col-12 col-lg-2 mb-3">
              <Link to="/">
                <Logo />
              </Link>
              <p className="text-center text-white"><small>{i18n.__("Copyright 2023")}</small></p>
            </div>

            <div className="text-center text-lg-left col-12 col-lg-2 mb-3">
              <Tourisms />
            </div>
            <div className="text-center text-lg-left col-12 col-lg-2 mb-3">
              <GovernmentAgencies />
            </div>
            <div className="text-center text-lg-left col-12 col-lg-2 mb-3">
              <SocialMedia />
            </div>
            {/* <div className="text-center text-lg-left col-12 col-lg-4 mb-3">
              <DotDirectories />
            </div> */}
            <div className="text-center text-lg-left col-12 col-lg-2 mb-4">
              <Countries />
            </div>
            {/* <div className="text-center text-lg-left col-12 col-lg-2 mb-3">
              <p className="text-white font-weight-bold">OFFICIAL PARTNER</p>
              <a href="https://guidetothephilippines.ph/?utm_medium=organic&utm_source=dot&utm_campaign=launch" target="_blank" rel="noopener noreferrer"><img src="/images/dot-gttplogo.png" alt="gttplogo" className="img-fluid mx-auto d-block"/></a>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default FooterSection;
