import React, { Component, Fragment } from 'react';
import { 
  Card, 
  CardImg, 
  CardImgOverlay, 
  CardText, 
  CardBody, 
  Button, 
  Container, 
  Row, 
  Col 
} from 'reactstrap';
import { dotConfig } from '../config';
import { HashLink as Link } from 'react-router-hash-link';

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import  { Redirect } from 'react-router-dom';

// KC Components


const HomepageBanner = ({id, webSrc, mobSrc}) => {
  return (
          <div id={id}>
            <img src={webSrc} alt="banner" className="img-fluid mx-auto web-banner" />
            <img src={mobSrc} alt="banner" className="img-fluid mx-auto mobile-banner" />
          </div>
    );
}

const InnerPageBanner = ({id, bg, title, content}) => {
  return (
    <div id={id} className="innerpagebanner d-flex bannerstyle align-items-center" style={{ background: 'url('+ bg + ')', backgroundSize: 'cover' }}>
      <Container>
        <Row>
          <article>
            <h1 className="text-white display-3 font-weight-bold" dangerouslySetInnerHTML={{__html: title}}/>
            <div className="d-flex">
              <img src="/images/dot-home-banner-icon.png" className="align-self-center" alt="dot-home-banner-icon"/>
              <p className="text-white pl-2 mb-0" dangerouslySetInnerHTML={{__html: content}}/>
            </div>
           {/* <h1> </h1>*/}
          </article>
        </Row>
      </Container>

    </div>
  )
}


const SectionHeader = ({bgcolor, title}) => {
	return(
		<div className={`content-sectionheader ${bgcolor} text-white pt-3 pb-2 px-3 d-inline-block`}>
			<h2 className="h3"><strong>{title}</strong></h2>
		</div>
	);
}

class RenderCarousel extends Component{

  constructor(props) {
    super(props);
    this.state = {
      throttled: false
    };
  }

  resize = () => {

    if (!this.state.throttled) {
      // actual callback action
      this.forceUpdate()
      // we're throttled!
      // throttled = true;
      this.setState({
        throttled: true
      })
      // set a timeout to un-throttle
      setTimeout(() => {
        this.setState({
          throttled: false
        })
      }, 500);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  render(){

    let slideScrollLG, slideScrollMD, slideScrollSM;

    let carouseldata = this.props.carouseldata;

    let setWidthClass = "";

    if (carouseldata.length < 4){
      if (window.innerWidth > 991){
        setWidthClass = "setCardWidth";
      }
      else if (window.innerWidth > 767 && window.innerWidth < 992){
        if (carouseldata.length === 3){
          setWidthClass = "";
        }
        else{
          setWidthClass = "setCardWidth";
        }
      }
      else{
        setWidthClass = "";
      }
    }
    else {
      setWidthClass = "";
    };

    if (carouseldata.length >= 4){
      slideScrollLG = 4;
      slideScrollMD = 3;
      slideScrollSM = 2;
    }

    else if (carouseldata.length === 3){
      slideScrollLG = 3;
      slideScrollMD = 3;
      slideScrollSM = 2;
    }
    else{
      slideScrollLG = carouseldata.length;
      slideScrollMD = carouseldata.length;
      slideScrollSM = carouseldata.length;
    }

    var settings = {
          className: setWidthClass,
          lazyLoad: true,
          dots: true,
          infinite: this.props.infinite === 'false' ? false : true,
          speed: 500,
          slidesToShow: slideScrollLG,
          slidesToScroll: slideScrollLG,
          responsive: [
            {
              breakpoint: 1199,
              settings:{
                slidesToShow: slideScrollMD,
                slidesToScroll: slideScrollMD
              }
            },
            {
              breakpoint: 991,
              settings:{

                slidesToShow: slideScrollSM,
                slidesToScroll: slideScrollSM
              }
            },
            {
              breakpoint: 767,
              settings:{
                className: setWidthClass,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

    return(
         <Slider {...settings}>
            {carouseldata}
         </Slider>
      );
    }
}

class RenderThreeCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      throttled: false
    };
  }

  resize = () => {

    if (!this.state.throttled) {
      // actual callback action
      this.forceUpdate()
      // we're throttled!
      // throttled = true;
      this.setState({
        throttled: true
      })
      // set a timeout to un-throttle
      setTimeout(() => {
        this.setState({
          throttled: false
        })
      }, 500);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  render() {
  
    let slideScrollLG, slideScrollMD;

    let carouseldata = this.props.carouseldata;

    let setWidthClass = "";

    if (carouseldata.length < 3) {
      if (window.innerWidth > 992) {
        setWidthClass = "setThreeCardWidth";
      }
    }
    else {
      setWidthClass = "";
    };

    if (carouseldata.length >= 3) {
      slideScrollLG = 3;
      slideScrollMD = 2;
    }
    else {
      slideScrollLG = carouseldata.length;
      slideScrollMD = carouseldata.length;
    }

    var settings = {
      className: setWidthClass,
      lazyLoad: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: slideScrollLG,
      slidesToScroll: slideScrollLG,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: slideScrollMD,
            slidesToScroll: slideScrollMD
          }
        },
        {
          breakpoint: 767,
          settings: {
            // className: setWidthClass,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Slider {...settings}>
        {carouseldata}
      </Slider>
    );
  }
}

const SetIcon = ({itemCategories, iconData}) => {
	if (!iconData){
		return (<div> No data found </div>);
	}
  else {
		return itemCategories.map((category, i) => {
      if (category !== ''){
        return <img alt="" key={i} className="mr-2 d-inline" src={iconData.find(element => element.tag === category).icon} />
      }
      else{
        return <div style={{minHeight: '37px'}} key={i}/>
      }
		});
	}
}

class TagCategory extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	     tagContents: [
          {
            title:"Dive",
            icon:"/images/icons/dot-explore-dive.png",
            tag:"Dive"
          },
          {
            title:"Nature",
            icon:"/images/icons/dot-explore-nature.png",
            tag:"Nature"
          },
          {
            title:"Sun and Beach",
            icon:"/images/icons/dot-explore-sun.png",
            tag:"Sun and Beach"
          },
          {
            title:"Adventure",
            icon:"/images/icons/dot-explore-adventure.png",
            tag:"Adventure"
          },
          {
            title:"Food and Hospitality",
            icon:"/images/icons/dot-explore-food.png",
            tag:"Food and Hospitality"
          },
          {
            title:"Events and Culture",
            icon:"/images/icons/dot-explore-events.png",
            tag:"Events and Culture"
          }
        ]
	     };
    }

	render() {
		return (
			<SetIcon itemCategories={this.props.itemCategories} iconData={this.state.tagContents} />
		);
	}
}

const DisplayExperience = ({experienceInfo}) => {
  const {field_feature_image, title, field_province, url_alias} = experienceInfo;
  const linkArray = url_alias.split('/');
  const activityLink = "/activities/" + linkArray[linkArray.length - 1];
  
  return(
    <Link to={activityLink}>
      <Card inverse className="border-0 card-image-overlay">
          <CardImg width="100%" src={dotConfig.appDomain + field_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt=""/>
          <CardImgOverlay>
            <h4><strong dangerouslySetInnerHTML={{__html: title}}/></h4>
            <p className="location position-absolute" dangerouslySetInnerHTML={{__html: field_province}} />
          </CardImgOverlay>
      </Card>
    </Link>
  );
}


function resizeTitle (title){
  if (title.length > 30){
    return <h5 className="font-weight-bold tile-header" dangerouslySetInnerHTML={{__html: title}} />
  }
  else{
    return <h4 className="font-weight-bold tile-header" dangerouslySetInnerHTML={{__html: title}} />
  }
}

const DisplayDestination = ({regionname, destinationinfo}) => {  
  const {field_feature_image, title, url_alias, field_description, region} = destinationinfo;
  const linkArray = url_alias.split('/');
  const eventLink = "/destinations/" + linkArray[linkArray.length - 1];

  return( 
    <Link to={eventLink} className="tileWidth">
      <Card inverse className="border-0 destination-info card-image-overlay">
          <CardImg width="100%" src={dotConfig.appDomain + field_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt=""/>
          <CardImgOverlay className="d-flex flex-column">
            <article className="d-flex" >
              <img src={'/images/dot-home-banner-icon.png'} className="img-fluid align-self-center" alt="calendar-icon"/>
              <p className="align-self-center mb-0 ml-2">{region ? region : regionname}</p>
            </article>
            {resizeTitle(title)}
            <p className="sub-info" dangerouslySetInnerHTML={{__html: field_description}} />
          </CardImgOverlay>
      </Card>
    </Link>
  );
}

const DisplayActivityInterest = ({activityInfo}) =>{
  const {field_feature_image, title, field_description, field_activity_type, url_alias} = activityInfo;

  const linkArray = url_alias.split('/');
  const activityLink = "/activities/" + linkArray[linkArray.length - 1];

	return(
		
      <Link to={activityLink} className="tileWidth">
			  <Card inverse className="border-0 card-image-overlay">
            <CardImg width="100%" src={dotConfig.appDomain + field_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt=""/>
		        <CardImgOverlay className="activity-tile">
              {
                field_activity_type != null ? <TagCategory itemCategories={field_activity_type.split("|")}/> : ""
              }
		          {resizeTitle(title)}
		          <p className="activity-intro" dangerouslySetInnerHTML={{__html: field_description}}/>
		        </CardImgOverlay>
		    </Card>
      </Link>

	);
}

const RenderActivity = ({activitydata}) => {
	if (activitydata.length){
	  return activitydata.map(function (activityContent, index) {
      return (<div className="d-inline-flex tileWidth" key={index}><DisplayActivityInterest  activityInfo={activityContent}/></div>)
		})
	}
	else{
	  return (<div>No activity available</div>)
	}
}

const DisplayEvent = ({eventinfo}) => {
  
  const {field_feature_image, title, field_location, field_event_date, url_alias} = eventinfo;
  
  const linkArray = url_alias.split('/');
  const eventLink = "/events/" + linkArray[linkArray.length - 1];

  return(
    <Link to={eventLink} className="tileWidth">
      <Card inverse className="border-0 festival-info card-image-overlay">
          <CardImg width="100%" src={dotConfig.appDomain + field_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt=""/>
          <CardImgOverlay className="d-flex flex-column">
            <article className="d-flex" >
              <img src={'/images/icons/dot-festivalicon.png'} className="img-fluid align-self-center" alt="calendar-icon"/>
              <p className="align-self-center mb-0 sub-info">{field_event_date}</p>
            </article>
            {resizeTitle(title)}
            <p className="sub-info" dangerouslySetInnerHTML={{__html: field_location}} />
          </CardImgOverlay>
      </Card>
    </Link>
  );
}

const RenderEvent = ({eventdata}) => {
	if (eventdata.length){
	  return eventdata.map(function (eventContent, index) {
			return (<DisplayEvent key={index} eventinfo={eventContent}/>)
		})
	}
	else{
	  return (<div><h3>No event available on this month</h3></div>)
	}
}

class LoadMore extends Component {
  constructor (props){
    super (props);
    this.state = {limit: 6};
    this.loadMoreData = this.loadMoreData.bind(this);
  }
  
  componentDidUpdate (nextProps) {
    if (nextProps.filteredData !== this.props.filteredData){
      this.setState({limit: 6});
    }
  }

  loadMoreData() {
    this.setState({limit: this.state.limit + 6});
  }

  renderData() {
    const {filteredData, page} = this.props;

    let newdata = filteredData.slice(0, this.state.limit);

      if (page === "seeanddo"){
        return <RenderActivity activitydata={newdata} />

      }
      if (page === "festivals"){
        return <RenderEvent eventdata={newdata} />
      }

    newdata = filteredData.slice(0, 6);

   }

  render() {
    const {filteredData} = this.props;

    if (filteredData.length < 7){
      return (<div className=" d-flex justify-content-center flex-wrap">{this.renderData()}</div>)
    }
    else {
      if (this.state.limit < filteredData.length){
      return <Fragment>
              <div className=" d-flex justify-content-center flex-wrap">
                {this.renderData()}
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <Button outline color="secondary" className="m-3 btn-loadmore text-white" onClick={this.loadMoreData}>Load more</Button>
                </div>
              </div>
            </Fragment>
    }
    else{
      return (<div className=" d-flex justify-content-center flex-wrap">{this.renderData()}</div>)
      }
    }
  }
}

// Jeff Components

const RenderImage = props => {
    return (
      <img className="img-fluid" {...props} alt="" />
    );
  };

const Logo = () => (
    <RenderImage className="mx-auto d-block img-fluid" src="/images/dot-logo-lovetheph.png" />
);

const RenderContent = props => {
  // console.log(string);
  return (
    <span {...props}>
      {props.children}
    </span>
  );
};

const WhereToStayLayout = ({ locations }) => {

  var car_content = locations.map(location => {
      return (
        <div className="px-2 pt-2 mb-2" key={location.title}>
          <Card className="border-0">
            <CardImg
              className="rounded-0"
              width="100%"
              src={dotConfig.appDomain + location.field_main_image.replace(/\\\//g, "/")} alt="Card image cap"
            />
            <CardBody
                className="text-white pt-2 three-cards-layout"
              style={{ backgroundColor: '#3a3a3a'}}
            >
              {/* <img src={location.icon} role="presentation" /> */}
                <CardText className="font-weight-bold" dangerouslySetInnerHTML={{ __html: location.title }} />
              <CardText
                style={{ lineHeight: '1' }}
                className="mb-0"
              ><small dangerouslySetInnerHTML={{__html: location.field_address}}/>
              </CardText>
              <CardText><small dangerouslySetInnerHTML={{__html: location.field_contact_number}}/></CardText>
            </CardBody>
          </Card>
        </div>
      );
    })
  return (<RenderThreeCarousel carouseldata={car_content} />)
};

const InnerLandingIntroTextLayout = ({ introductions }) => {

  return (
    // <Col xs="12" lg="6" key={introductions.title}>
    <Col xs="12" lg={{size: 10, offset: 1}} className="text-center" key={introductions.title}>
      {/* <h5>
        <RenderContent className="font-weight-bold redbg py-3 px-3 d-inline-block text-white">
          Welcome to <span dangerouslySetInnerHTML={{ __html: introductions.title }} />
        </RenderContent>
      </h5> */}
      <article className="py-5 px-3" style={{ color: '#4b4b4b' }}>
          <RenderContent className="d-block">
              <div style={{ wordWrap: 'break-word'}} dangerouslySetInnerHTML={{ __html:introductions.field_description }} />
          </RenderContent>

          {/* <SubSection header="How to get there:" body={introductions.field_how_to_get_there} />
          <br />
          {
            introductions.hasOwnProperty('field_tours_link')
              ? 
                introductions.field_tours_link.length > 0 &&
                <Button href={introductions.field_tours_link} target="_blank" className="booktour-btn font-weight-bold mt-2 px-sm-4 py-2">Book a Tour with <img src="/images/dot-gttplogo-booktour.png" alt="gttplogo" className="img-fluid" /></Button>
              : 
                ''
          } */}
      </article>
    </Col>
  );
};



const EventsAndFestivalLayout = ({ eventsData }) => {
  var car_content =  eventsData.map(function (eventData, index) {
        return (<DisplayEvent eventinfo={eventData} key={index} />)
      })
  return (<RenderCarousel carouseldata={car_content}/>)
}


const UpcomingEventsAndFestivalsLayout = ({ upcomingEvents }) => {
  var car_content = upcomingEvents.map(function (upcomingEvent, index) {
      return (<DisplayEvent eventinfo={upcomingEvent} key={index} />)
  })
  return (<RenderCarousel carouseldata={car_content}/>)
}


const WhatsNearLayout = ({ nearLocations }) => {
  var car_content = nearLocations.map(nearLocation => {
      // var nodeLink;
      var linkArray;
      switch(String(nearLocation.type)) {
        case 'Destinations':
          linkArray = nearLocation.url_alias.split('/');
          // nodeLink = "/destinations/" + linkArray[linkArray.length - 1];
          break;
        case 'Activities':
          linkArray = nearLocation.url_alias.split('/');
          // nodeLink = "/activities/" + linkArray[linkArray.length - 1];
          break;
        default:
          linkArray = nearLocation.url_alias.split('/');
          // nodeLink = "/activities/" + linkArray[linkArray.length - 1];
      }

      return (
        // <div key={nearLocation.title}>
        //   <Link to={nodeLink} >
        //     <Card inverse className="border-0 card-image-overlay"  id="setCard">
        //       <CardImg width="100%" src={dotConfig.appDomain + nearLocation.field_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt=""/>
        //       <CardImgOverlay>
        //         <h4 className="font-weight-bold" dangerouslySetInnerHTML={{__html: nearLocation.title}}/>
        //       </CardImgOverlay>
        //     </Card>
        //   </Link>
        // </div>
        <Fragment key={nearLocation.title}>
          {
            nearLocation.type === 'Activities' ? <DisplayActivityInterest activityInfo={nearLocation} /> : <DisplayDestination destinationinfo={nearLocation}/>
          }
        </Fragment>
      );
    })
  return (<RenderCarousel carouseldata={car_content} />)
};

const BookToursLayout = ({ tourList }) => {
  var car_content = tourList.map(tour => {
      return (
        <div key={tour.title}>
          <a target="_blank" href={tour.field_tours_link} rel="noopener noreferrer">
            <Card inverse className="border-0 card-image-overlay"  id="setCard">
              <CardImg width="100%" src={dotConfig.appDomain + tour.field_feature_image.replace(/\\\//g, "/")} className="img-fluid rounded-0" alt=""/>
              <CardImgOverlay>
                <h4 className="font-weight-bold" dangerouslySetInnerHTML={{__html: tour.title}} />
              </CardImgOverlay>
            </Card>
          </a>
        </div>
      );
    })
  return (<RenderCarousel carouseldata={car_content}/>)
};

const Banner = ({ background, bannertext }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center bannerstyle"
      style={{ background: `url(${background}) top center no-repeat`, backgroundSize: 'cover'}}
    >
      <h1 style={{fontFamily: 'Barabara', textTransform: 'uppercase'}} className="text-white text-center display-3 font-weight-bold" dangerouslySetInnerHTML={{ __html: bannertext }} />
    </div>
  )
}

const SubSection = ({header,body}) => {
  if (body !== "") {
      return(
          <React.Fragment>
              <RenderContent className="d-block font-weight-bold py-3 h5 mb-0">
                  {header}
              </RenderContent>
              <RenderContent className="d-block">
                  <div dangerouslySetInnerHTML={{ __html:body }} />
              </RenderContent>
          </React.Fragment>
      );
  } else {
      return null;
  }
}

function HomeRedirect() {
  return (
    <Redirect to="/" />
  )
}


class GTTPToursSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedContents: [],
      nodeIds: this.props.nodeIds
    };
  }

  loadData() {
    const fetchURL = dotConfig.apiUrl + 'tours/' + encodeURI(this.state.nodeIds.replace(/\s/g, ''));
    fetch(fetchURL)
    .then(response => response.json())
    .then(response => {
      this.setState({
        fetchedContents: response
      });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.nodeIds !== prevState.nodeIds) {
        return {
          nodeIds: nextProps.nodeIds
        }
      }
      return null;
    }

  componentDidMount() {
      this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.nodeIds !== prevState.nodeIds) {
      this.loadData();
    }
  }

  render() {
    return (
       <BookToursLayout tourList={this.state.fetchedContents} />
      );
  }
}


function HeaderSection ({icon, header, subheader, fontColor, id}){
  return (
    <Container className="mb-3" id={id}>
      <Row>
        <Col className="d-flex align-items-center">
          <img src={icon} alt={header} className="img-fluid mr-3" />
          <div>
            {
              id === 'tours'
              ?
              <Fragment>
                <h3 className="font-weight-bold text-uppercase d-inline align-middle mb-0" style={{color: fontColor}}>{header}</h3>
                <img src='/images/dot-gttplogo-booktour.png' className='ml-2' alt="gttp-tours"/>
              </Fragment>
              :
              <h3 className="font-weight-bold text-uppercase mb-0" style={{color: fontColor}}>{header}</h3>    
            }
            {
              subheader !== undefined ? <p>{subheader}</p> : ""
            }
          </div>
        </Col>
      </Row>
    </Container>
  )
}



export { SubSection, DisplayExperience, DisplayActivityInterest, DisplayEvent, LoadMore, RenderCarousel, RenderThreeCarousel, SectionHeader, WhatsNearLayout, InnerLandingIntroTextLayout, EventsAndFestivalLayout, RenderImage, Logo, RenderContent, WhereToStayLayout, UpcomingEventsAndFestivalsLayout, Banner, HomepageBanner, InnerPageBanner, BookToursLayout, HomeRedirect,  DisplayDestination, GTTPToursSection, HeaderSection };