import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import {
  RenderContent,
  Banner,
  InnerLandingIntroTextLayout,
  EventsAndFestivalLayout,
  DisplayActivityInterest,
  RenderCarousel,
  GTTPToursSection
} from "../components/Commoncomponents";
import { WhereToStay, WhatsNearFold, DestinationsAccordion } from "./Commoncontainers";
import { dotConfig } from "../config";

// import IlocosSur from "./SafeTrip/IlocosSur";
// import Baguio from "./SafeTrip/Baguio";

const SeeAndDoLayout = ({ activitiesData }) => {
  var car_content = activitiesData.map(function(activityData, index) {
    return (<div className="mb-2" key={index}><DisplayActivityInterest activityInfo={activityData}/></div>)
  });
  return <RenderCarousel carouseldata={car_content} />;
};

class SeeAndDoFold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activitiesData: this.props.activitiesdata,
      // nodeId: this.props.nodeId
    };
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.activitiesdata !== prevProps.activitiesdata) {
      return {
        activitiesData: nextProps.activitiesdata,
      };
    }
    return null;
  }

  render() {
    return (
      <Container>
        <Row>
          <Col className="mt-3 mb-4">
            <RenderContent className="font-weight-bold lightgreenbg py-3 px-3 mb-2 h5 text-white">
              See and Do
            </RenderContent>
          </Col>
        </Row>
        <Row className="pt-3 pb-5">
          <Col className="px-4">
            <SeeAndDoLayout activitiesData={this.state.activitiesData} />
          </Col>
        </Row>
      </Container>
    );
  }
}

class FestivalAndEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventsData: this.props.eventsdata,
    };
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.eventsdata !== prevProps.eventsdata) {
      return {
        eventsData: nextProps.eventsdata
      };
    }
    return null;
  }

  render() {
    const { eventsData } = this.state;
    if (eventsData.length === 0) {
      return null;
    }

    return (
      <Container>
        <Row>
          <Col className="mt-3 mb-4">
            <RenderContent className="font-weight-bold darkbluebg py-3 px-3 mb-2 h5 text-white">
              Events and Festivals
            </RenderContent>
          </Col>
        </Row>
        <Row className="pt-3 pb-5">
          <Col className="px-4">
            <EventsAndFestivalLayout eventsData={eventsData} />
          </Col>
        </Row>
      </Container>
    );
  }
}

class BookToursFold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodeIds: this.props.nodeIds
    };
  }
  render() {
    return (
        <Container id="whatsnear">
          <Row>
            <Col className="mb-4 mt-3">
              <RenderContent className="font-weight-bold py-3 px-3 mb-2 h5" style={{backgroundColor: '#fbcf43', color: '#7e7f81'}}>
                Book Tours
              </RenderContent>
              <img src="/images/dot-gttplogo.png" alt="gttplogo" className="ml-3" width="150" style={{marginTop: '-8px'}}/>
            </Col>
          </Row>
          <Row className="pt-3 pb-5">
            <Col className="px-4">
              <GTTPToursSection nodeIds={this.state.nodeIds}/>
            </Col>
          </Row>
      </Container>
    );
  }
}


export function NewSectionHeaders ({header, copy, type, destination}){
  return (
    <Row className="mb-4">
      <Col className="text-center" xs="12" lg={{size: 8, offset: 2}}>
        {
          type === "dynamic"
          ? 
            <h3 style={{color: '#0072bc'}} className="font-weight-bold" dangerouslySetInnerHTML={{__html: header + '&nbsp;' + destination}} />
          :
            <h3 style={{color: '#0072bc'}} className="font-weight-bold" dangerouslySetInnerHTML={{__html: header}} />
        }
        <p dangerouslySetInnerHTML={{__html: copy}}/>
      </Col>
    </Row>
  )
}

// const boracay_gettinginfo = [
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-getting-test.jpg",
//     "title": "Test before you travel",
//     "copy": "A negative RT-PCR result not earlier than seventy-two (72) hours prior to travelling to Boracay is mandatory. Travelers are strongly advised to observe strict quarantine immediately after undergoing the test until the date of travel to the island."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-getting-booking.jpg",
//     "title": "Book in DOT-accredited establishments only",
//     "copy": "To ensure safety and the quality of service, all travelers are required to pre-book in DOT-accredited hotels and resorts issued with a Certificate of Authority to Open. You can find the list of these accommodation establishments <a href='https://docs.google.com/spreadsheets/d/e/2PACX-1vQoMhSz_Zc_-q9ZKc6jluMV1d-v-r9UaR9rEuF-V7t81eWZTPG06ud6ebGJQ2GbkSPbM7DB5jbSYpYm/pubhtml' target='_blank' rel='noreferrer noopener'>here.</a>"
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-getting-form.jpg",
//     "title": "Register your visit online",
//     "copy": "<ol><li>Access <a href='http://aklan.gov.ph' target='_blank' rel='noreferrer noopener'>http://aklan.gov.ph</a> and click the <strong>Online Health Declaration Card</strong> button then choose <strong>Boracay</strong> in the “Where To?” page.</li><li>Fill out the required fields and submit. A confirmation with instructions will be displayed. </li><li>Save a copy of the <strong>Health Declaration Card (HDC)</strong> on your smartphone or have it printed. </li><li>Attach a copy of the HDC together with a copy of your RT-PCR Negative Result, confirmed flight and booking details, and your valid ID, then email it to touristboracay@gmail.com with this subject format: <strong>OHDC - Family Name, First Name</strong> (example: OHDC - Dela Cruz, Juan). Registration for multiple tourists and their documents can be sent in one email. </li><li>Wait for the email response within the day. If approved, a copy of your HDC and Tourist QR Code will be sent back to you. Present these to authorities in the borders for scanning.</li> </ol>"
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-getting-age.jpg",
//     "title": "No age restriction",
//     "copy": "Age restrictions for tourists visiting Boracay shall be relaxed. Restrictions as to persons with comorbidities will remain to be strictly enforced."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-getting-port.jpg",
//     "title": "Follow port of entry guidelines",
//     "copy": "Airlines are advised that the Godofredo P. Ramos Airport in Caticlan, Municipality of Malay will be the only port of entry for tourists traveling by air. A central point shall be established to screen tourists travelling by land, air, or sea."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-getting-familiarize.jpg",
//     "title": "Familiarize yourself with local health and safety facilities and guidelines",
//     "copy": "Stringent health and safety guidelines, emergency response protocols, and a COVID-19 Laboratory are in place as you enter Boracay. See FAQs to learn more."
//   } 
// ]

// export function GettingTo({gettinginfo, copy, video, destination}){
//   return(
//     <Container className="pt-3 pb-5">
//       {
//         video !== undefined
//         ?
//         <Row className="mb-4">
//           <Col xs="12" lg={{size: 10, offset: 1}}>
//             <div className="embed-responsive embed-responsive-16by9">
//                   <iframe  src={video} className="embed-responsive-item" allowfullscreen></iframe>
//               </div>
//           </Col>
//         </Row>
//         :
//         ""
//       }
//       <NewSectionHeaders header="Getting to" copy={copy} destination={destination} type="dynamic"/>
//       {
//         gettinginfo.map((info, index) => {
//           return (
//             <Row className="mb-4 mb-lg-5" key={index}>
//               <Col xs="12" lg={{size: 6, order: index % 2 === 0 ? 0 : 2}}>
//                 <img src={info.img} alt={info.title} className="img-fluid mx-auto d-block"/>
//               </Col>
//               <Col xs="12" lg="6" className="mt-3">
//                 <div className="absolute-center">
//                   <h4 className="font-weight-bold" dangerouslySetInnerHTML={{__html: info.title}}/>
//                   <p dangerouslySetInnerHTML={{__html: info.copy}}/>
//                 </div>
//               </Col>
//             </Row>
//           )
//         })
//       }
//     </Container>
//   )
// }

// const boracay_traveltips = [
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-tips-undertheweather.jpg",
//     "title": "Don’t travel if you’re feeling under the weather",
//     "copy": "Protect your health and the well-being of others. If you are showing symptoms of COVID-19 (fever, sore throat, difficulty breathing, etc.), please rebook your trip and consult a doctor."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-tips-book.jpg",
//     "title": "Book through a DOT-accredited tour operator or travel agency",
//     "copy": "For a worry-free travel experience, let our DOT-accredited tour operators and travel agencies handle the testing, booking, and itineraries."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-tips-register.jpg",
//     "title": "Remember to register before swimming in the ocean",
//     "copy": "Go to designated beach stations and have your temperature checked before going into the water. You can swim in the marked areas between red and yellow flags from 6am to 6pm."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-tips-puv.jpg",
//     "title": "No face mask + No face shield = No Ride for all PUVs",
//     "copy": "Keep your safety gear on and observe proper physical distancing. Only a maximum of two (2) passengers on tricycles and a maximum of four (4) passengers on e-trikes are allowed."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-tips-doh.jpg",
//     "title": "Get tested only in licensed labs",
//     "copy": "Ensure that you are getting your RT-PCR Tests in accredited laboratories <a href='https://hfsrb.doh.gov.ph/?page_id=1729' target='_blank' rel='noreferrer noopener'>here</a>."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-tips-qr.jpg",
//     "title": "Keep your tourist QR code with you at all times",
//     "copy": "Have a printed copy on hand or digital copy in your smartphone. The QR Code will be used in accessing services in Boracay Island"
//   }
// ]


// function TravelTips ({traveltips, copy, color1, color2}){
//   return(
//     <section style={{backgroundImage: 'linear-gradient(' +color1+ ',' +color2+ ')'}} className="py-5">
//       <Container>
//         <NewSectionHeaders header="Travel Tips" copy={copy}/>
//         <Row>
//           {
//             traveltips.map(tip => {
//               return (
//                 <Col xs="12" lg="6" className="mb-4 mb-lg-5" key={tip.title}>
//                   <div className="d-block d-lg-flex">
//                     <img src={tip.img} alt={tip.title} className="img-fluid mx-auto d-block w-100 h-100"/>
//                     <div className="ml-0 ml-lg-3 mt-3">
//                       <h5 className="font-weight-bold" dangerouslySetInnerHTML={{__html: tip.title}}/>
//                       <p dangerouslySetInnerHTML={{__html: tip.copy}}/>
//                     </div>
//                   </div>
//                 </Col>
//               )
//             })
//           }
//         </Row>
//       </Container>
//     </section>
//   )
// }

// const boracay_activities = [
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-activities-dining.jpg",
//     "title": "Dining",
//     "copy": "Restaurants are open with minimum public health standards in place."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-activities-swimming.jpg",
//     "title": "Swimming",
//     "copy": "Dipping and swimming are allowed in designated swimming areas from 6 AM - 6 PM."
//   },
//   {
//     "img": "/images/dot-destinations/dot-destinations-boracay-activities-watersports.jpg",
//     "title": "Water Sports",
//     "copy": "Different water sports such as parasailing, kitesurfing, jet skiing, among others are allowed at reduced capacity."
//   }
// ]

// export function ActivitiesSection({color1, color2, copy, activities}){
//   return(
//     <section style={{backgroundImage: 'linear-gradient(' +color1+ ',' +color2+ ')'}} className="py-5">
//       <Container>
//         <NewSectionHeaders header="Activities" copy={copy} />
//         <Row>
//           <Col className="d-block d-lg-flex">
//             {
//               activities.map(info => {
//                 return (
//                   <div className="mb-4 w-100" key={info.title}>
//                     <img src={info.img} alt={info.title} className="img-fluid mx-auto d-block mb-3"/>
//                     <div className="px-3 text-center">
//                       <h5 className="font-weight-bold mb-2" dangerouslySetInnerHTML={{__html: info.title}}/>
//                       <p dangerouslySetInnerHTML={{__html: info.copy}}/>
//                     </div>
//                   </div>
//                 )
//               })
//             }
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   )
// }

// const boracay_faqs = [
//   {
//     "title": "Who are allowed to travel to Boracay?",
//     "content": "<p>Only travelers who underwent RT-PCR and are negative for COVID-19 not earlier than 72 hours prior to travelling to Boracay are allowed to enter the island.</p><p>Visitors from destinations under a General Community Quarantine (GCQ) classification are also allowed to visit the island provided that they test negative for COVID-19 thru RT-PCR.</p> <p>Age restriction has been relaxed to allow travelers below 21 and above 60 years old with no comorbidity to travel to Boracay.</p>"
//   },
//   {
//     "title": "Is a declared daily itinerary required for all visitors?",
//     "content": "<p>All tourists are required to register online at <a href='http://aklan.gov.ph' target='_blank' rel='noreferrer noopener' style='color: #fff!important; text-decoration: underline;'>http://aklan.gov.ph</a> and fill-up an health declaration form. A QR code will be sent after a successful registration. </p><p>All the hotels, food establishments, and water sports will have a QR scanning system.</p><p>On top of the QR records, the hotels and resorts will be advised to look after their own guests including knowledge of their whereabouts.</p>"
//   },
//   {
//     "title": "What happens if a traveler shows symptoms of COVID-19 during the trip?",
//     "content": "<p><strong>If symptomatic upon arrival in the airport:</strong></p><ol><li>The tourist will be sent to the Airport Isolation Area.</li><li>Airport COVID Medical Team will assess the patient.</li><li>If upon assessment tourist is a COVID suspect (mild), the tourist will be sent to a <strong>Provincial Quarantine Facility (Aklan Training Center).</strong><br/>If COVID suspect results showed moderate-severe, the tourist will be sent to the Provincial designated COVID Referral Hospital (DRSTMH) for admission. Airport ambulance will be used to transport the patient.</li><li>All COVID suspects will be swabbed for RTPCR Testing.</li></ol><br/><p><strong>If symptomatic in Boracay</strong></p><ol><li>The tourist or the hotel’s Health & Safety Officer will call the Boracay COVID Hotline Number, 152. Ciriaco S. Tirol Hospital (CSTH) manages the COVID hotline.</li><li>A CSTH Nurse will get the pertinent details of the symptomatic tourist and will inform the CSTH COVID Responder.</li><li>A CSTH COVID Responder will be deployed to the location of the tourist and bring the tourist to CSTH (Designated COVID Isolation Area).</li><li>A CSTH Doctor will conduct evaluation and medical assessment, will request for diagnostic tests, and will do initial management of the patient.</li><li>If upon assessment tourist is a COVID suspect (mild), the tourist will be sent to a Provincial Quarantine Facility (Aklan Training Center). If COVID suspect results showed moderate-severe, the tourist will be sent to the Provincial designated COVID Referral Hospital (DRSTMH) for admission. Ambulance within the island and the mainland, including the sea ambulance are already in place.</li><li>All COVID suspects will be swabbed for RTPCR Testing</li></ol>"
//   },
//   {
//     "title": "If positive, will all the passengers be quarantined? If yes, where?",
//     "content": "<ul><li>Only the positive tourist and his/her close contacts will be quarantined.</li><li>The Municipal Contact Tracing Team will be activated once there is a positive case. The contact tracing team will trace all the close contacts of the positive case from 2 days before the start of the onset of symptoms.</li><li>The data from the QR Code system will be helpful in the fast identification of the close contacts.</li><li>The airline seat number of the positive case will be identified and the passengers 4 rows front, back, and sides will be notified through the Hotel & Safety Officers of the Hotels.</li><li>The pumpboat seat number of the positive case will also be identified and the passengers 4 rows front, back, and sides will be notified through the Hotel & Safety Officers of the Hotels.  </li><li>All the tourist’s itinerary will also be identified </li><li>These identified close contacts will be requested to self-isolate in their individual hotel rooms.  They will then be scheduled for testing.</li><li>If tourist is positive with mild symptoms, he/she will be transferred from Aklan Training Center to a <strong>Provincial Designated Hotel for COVID positive with mild symptoms</strong>.</li><li>If tourist is positive with moderate-severe symptoms, he/she will be referred to the <strong>Provincial designated COVID Referral Hospital (DRSTMH)</strong> for admission.</li></ul>"
//   }
// ]

// export function FAQsSection({faqs, color1, color2, copy}){
//   return(
//     <section style={{backgroundImage: 'linear-gradient(' +color1+ ',' +color2+ ')'}} className="py-5">
//       <Container>
//         <Row className="mb-4">
//           <Col className="text-center text-white" xs="12" lg={{size: 8, offset: 2}}>
//             <h3 className="font-weight-bold">Frequently Asked Questions</h3>
//             <p dangerouslySetInnerHTML={{__html: copy}}/>
//           </Col>
//         </Row>
//         <Row>
//           <Col xs="12" lg={{size: 8, offset: 2}}>
//             <DestinationsAccordion accordion={faqs} type="faq"/>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   )
// }

// function AccreditedHotels({accreditedhotels}){
//   return(
//     <section style={{backgroundImage: 'linear-gradient(#418ebc, #2a6cc0)'}} className="py-5">
//       <Container>
//         <Row>
//           <Col xs="12" lg={{size: 8, offset: 2}} className="text-white text-center">
//             <h4 className="font-weight-bold mb-4">List of DOT-Accredited Hotels and Resorts issued with a Certificate of Authority to Operate</h4>
//             <p>Here are the list of DOT-certified hotels and resorts in Boracay that you may check in to make your visit in the island paradise a remarkable one.</p>
//           </Col>
//         </Row>
//         <Row className="my-4">
//           <Col xs="12" lg={{size: 8, offset: 2}} className="text-center">
//             <DestinationsAccordion accordion={accreditedhotels}/>
//           </Col>
//         </Row>
//         <Row>
//           <Col xs="12" lg={{size: 8, offset: 2}} className="text-white text-center">
//             <div className="text-center"><Button className="btn-loadmore text-white" style={{backgroundColor: 'transparent'}} href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQoMhSz_Zc_-q9ZKc6jluMV1d-v-r9UaR9rEuF-V7t81eWZTPG06ud6ebGJQ2GbkSPbM7DB5jbSYpYm/pubhtml" target="_blank" rel="noreferrer noopener">Learn More</Button></div>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   )
// }


// Main Component

class DotDestinations extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      destinationData: [],
      activitiesData: [],
      eventsData: [],
      accreditedhotelsData: [],
      destinationNode: this.props.match.params.nodeid,
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
    if (!this.state.dropdownOpen) {
      document.body.classList.add("overlay-active");
    } else {
      document.body.classList.remove("overlay-active");
    }
  }

  loadData() {

    //Fetch Destination Data
    // const fetchURL =
    //   dotConfig.apiUrl +
    //   "destinations/" +
    //   this.state.destinationNode;
    // console.log(this.state.destinationNode);
    const fetchURL =
      dotConfig.apiUrl +
      "destinations?alias=/destinations/" +
      this.state.destinationNode;
    fetch(fetchURL)
      .then(response => response.json())
      .then(response => {
        this.setState({
          destinationData: response
        });

        //Fetch Activities Data
        const activitiesFetchURL =
        dotConfig.apiUrl +
        "destination-activities/" +
        response[0].nid;

        fetch(activitiesFetchURL)
        .then(activitiesresponse => activitiesresponse.json())
        .then(activitiesresponse => {
          this.setState({
            activitiesData: activitiesresponse
          });
        })
        .catch(error => {
          console.log(error);
        });

        //Fetch Events Data
        const eventsFetchURL =
        dotConfig.apiUrl +
        "destination-events/" +
        response[0].nid;

        fetch(eventsFetchURL)
        .then(eventsresponse => eventsresponse.json())
        .then(eventsresponse => {
          this.setState({
            eventsData: eventsresponse
          });
        })
        .catch(error => {
          console.log(error);
        });


        //Fetch accreditedhotels Data
        const accreditedhotelsFetchURL = '/data/accreditedhotels_boracay.json';

        fetch(accreditedhotelsFetchURL)
        .then(accreditedhotelsresponse => accreditedhotelsresponse.json())
        .then(accreditedhotelsresponse => {
          this.setState({
            accreditedhotelsData: accreditedhotelsresponse
          });
        })
        .catch(error => {
          console.log(error);
        });

      })
    .catch(error => {
      console.log(error);
    });
    
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.nodeid !== prevState.destinationNode) {
      return {
        destinationNode: nextProps.match.params.nodeid
      };
    }
    return null;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.destinationNode !== prevState.destinationNode) {
      this.loadData();
      window.scrollTo(0, 0);
    }
  }

  bgSequence() {

  }

  render() {
    const { destinationData, activitiesData, eventsData, accreditedhotelsData } = this.state;

    const bgArray = ["darkgreybg", "", "lightgreybg", "","darkgreybg"];
    var bgCounrter = 0;
    if (destinationData.length === 0) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    } else {
      var destinationDisplayData = destinationData[0];
    }

    const background = `${
      dotConfig.appDomain
    }${destinationDisplayData.field_main_banner.replace(/\\\//g, "/")}`;

    let bannerTitle = "";
    if (destinationDisplayData.field_covid_reopen === "Yes") {
      bannerTitle = `WELCOME BACK TO ${destinationDisplayData.title}`;
    } else  {
      bannerTitle = `WELCOME TO ${destinationDisplayData.title}`;
    }

    return (
      <div id="dotlocations">
        <Banner
          background={background}
          bannertext={bannerTitle}
        />
        <Container>
          <Row>
            <InnerLandingIntroTextLayout
              introductions={destinationDisplayData}
            />
            {/* <Col className="d-none d-lg-block" lg="6">
              <img
                src="/images/dot-banig-large.jpg"
                className="mx-auto img-fluid d-block"
                alt="dot-banig-large"
              />
            </Col> */}
          </Row>
        </Container>

        {/* {this.state.destinationNode === 'boracay' &&
          <>        
            <GettingTo destination={destinationDisplayData.title} video="https://www.youtube.com/embed/27UyZjRFRbs" gettinginfo={boracay_gettinginfo} copy="Follow these guidelines in order to have a hassle-free and fun in Boracay when it reopens."/>
            <TravelTips traveltips={boracay_traveltips} copy="Longing to take a visit at the island paradise of Boracay? Do one last check before packing your bags and read the following reminders:" color1="#ffe3d7" color2="#f8bfac" />
            <ActivitiesSection copy="Below are some of the activities that local travelers may enjoy upon their visit in Boracay once it reopens on October 1." color1="#c5e5da" color2="#87c69b" activities={boracay_activities}/>
            <FAQsSection faqs={boracay_faqs} color1="#6ab1b3" color2="#2d9b98" copy="Here are some common inquiries about the local tourism in Boracay regarding its reopening, and their safety protocol with regards to COVID-19."/>
            <AccreditedHotels accreditedhotels={accreditedhotelsData}/>
          </> 
        } */}

        {/* {this.state.destinationNode === 'ilocos-sur' &&       
          <IlocosSur destination={destinationDisplayData.title} url={window.location.pathname}/>
        } */}

        {/* {this.state.destinationNode === 'baguio-city' &&
          <Baguio destination={destinationDisplayData.title} url={window.location.pathname}/>
        } */}
        
        {activitiesData.length > 0 && 
          <section className={bgArray[bgCounrter++]} id="destinations-seeanddo">
            <SeeAndDoFold activitiesdata={activitiesData} />
          </section>
        }

        {eventsData.length > 0 && 
          <section className={bgArray[bgCounrter++]} id="destination-festivals"> 
            <FestivalAndEvents  className={bgArray[bgCounrter]} eventsdata={eventsData} />
          </section>
        }

        {destinationDisplayData.field_where_to_stay.length > 0 && 
          <section className={bgArray[bgCounrter++]}>
            <WhereToStay nodeIds={destinationDisplayData.field_where_to_stay} />
          </section>
        }

        {destinationDisplayData.field_whats_near_me.length > 0 && 
          <section className={bgArray[bgCounrter++]} id="destinations-whatsnear">
            <WhatsNearFold section="destination" nodeIds={destinationDisplayData.field_whats_near_me} />
          </section>
        }
        
        {destinationDisplayData.field_tours.length > 0 && 
          <section className={bgArray[bgCounrter++]}>
            <BookToursFold nodeIds={destinationDisplayData.field_tours} />
          </section>
        }


      </div>
    );
  }
}

export default DotDestinations;
